.shimmer {
}

.shimmer--active {
  --color: #f6f7f8;
  --secondaryColor: #edeef1;
}

#root .shimmer--active .shine {
  background: var(--color);
  background-image: linear-gradient(
    to right,
    var(--color) 0%,
    var(--secondaryColor) 20%,
    var(--color) 40%,
    var(--color) 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 400px;
  /* position: relative; */
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
  color: transparent;
  pointer-events: none;
  border-color: transparent;
}

.shine--fit {
  width: fit-content;
}

.shine--round {
  border-radius: 50%;
}

.shimmer--active .shine *,
.shimmer--active .shine *::after,
.shimmer--active .shine *::before,
.shimmer--active .shine *::placeholder,
.shimmer--active .shine::placeholder {
  color: transparent !important;
  background-color: transparent !important;
  visibility: hidden;
  opacity: 0;
  cursor: progress;
  pointer-events: none;
}

/* .shimmer--finished .shine > * {
  opacity: 1;
  transition: opacity 300ms ease-in;
} */

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
