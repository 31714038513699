.container {
  position: fixed;
  right: 0;
  bottom: 0;
  height: 100%;
  transform: translateY(calc(100% - var(--headerHeight)));
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 4;
  transition: transform 0.3s ease-in-out;
  width: 31.25rem;
}

.containerActive {
  transform: translateY(0);
}

.my-bids--open .my-bids__toggle {
  box-shadow: none;
}

.main {
  flex: 1;
  width: 100%;
  background-color: #ffffff;
  padding: var(--sectionPadding);
  box-shadow: -0.1875rem 0 1.25rem 0 rgb(187 181 198 / 84%);
  overflow: auto;
}

.mainTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mainTopTitle {
  font-family: var(--archivo);
  font-size: 1.4375rem;
  font-weight: 500;
  color: #222b45;
}

.arrowContainer {
  width: 2rem;
  height: 2rem;
  border-radius: 0.375rem;
  background-color: #eaeef2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.arrow {
  color: #687c97;
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
}

.bidsTitle {
  display: flex;
  align-items: center;
  margin-top: 0.75rem;
  font-family: var(--archivo);
  font-size: 0.875rem;
  color: #687c97;
  border-bottom: solid 0.1062rem #edf2f6;
  padding-bottom: 16px;
}

.bidsTitle > svg {
  margin-right: 0.25rem;
}

.total {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: var(--archivo);
  font-size: 0.875rem;
  color: #687c97;
}

.total span {
  font-family: var(--archivo);
  font-size: 1rem;
  color: #687c97;
  padding-left: 0.5rem;
  font-weight: bold;
}

.noDataText {
  font-family: var(--archivo);
  font-size: 1rem;
  color: #687c97;
  line-height: 1.88;
  margin-top: 0.75rem;
}

.noBids {
  font-family: var(--archivo);
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  color: #687c97;
  margin: 48px 0;
}

.noBids svg {
  margin-bottom: 0.5rem;
}

.emptyBid {
  width: 27.6875rem;
  height: 5.625rem;
  padding: 0 0.75rem;
  border-radius: 0.625rem;
  background-color: rgba(234, 238, 242, 0.3);
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.emptyBidImage {
  width: 4rem;
  height: 4rem;
  opacity: 0.6;
  border-radius: 0.4375rem;
  background-color: #eaeef2;
}

.emptyBidDetails {
  display: flex;
  flex-direction: column;
  height: 4rem;
  justify-content: space-between;
  flex: 1;
  margin: 0 16px;
}

.emptyBidDetailsTop {
  display: grid;
  grid-template-columns: 0.4fr 0.7fr 0.5fr 0.7fr 0.8fr;
  grid-template-rows: 1.375rem;
  column-gap: 0.5rem;
}

.emptyBidDetailsBottom {
  opacity: 0.6;
  border-radius: 0.4375rem;
  background-color: #eaeef2;

  height: 1.375rem;
  width: 80%;
}

.emptyBidDetailsTop > div {
  opacity: 0.6;
  border-radius: 0.4375rem;
  background-color: #eaeef2;
}

.emptyBidCircle {
  width: 4rem;
  height: 4rem;
  opacity: 0.6;
  border-radius: 0.4375rem;
  background-color: #eaeef2;
  border-radius: 50%;
}

.bidsList {
  margin-top: 16px;
}

.bidsList > div:not(.bidsList > div:last-of-type) {
  border-bottom: solid 0.1062rem #edf2f6;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
}

@media (max-width: 48rem) {
  .container {
    position: fixed;
    left: 0;
    width: 100%;
  }

  .bidsList > div {
    grid-template-columns: 4.125rem auto 2.875rem;
  }

  .container :global(.ui-price) {
    font-size: 1rem;
  }
}
