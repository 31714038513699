.container :global(.select-filter__indicator) {
  color: hsl(0, 0%, 80%);
  padding: 0 8px;
}

.container :global(.select-filter__indicator-separator) {
  display: none;
}

.container :global(.select-filter__control) {
  border-radius: 30px;
  outline-style: none;
  border: solid 1.5px #c0cbd1;
  box-shadow: none;
  height: 35px;
  min-height: unset;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.container :global(.select-filter__control--is-focused),
.container :global(.select-filter__control--menu-is-open),
.container :global(.select-filter__control:hover) {
  border: solid 1.5px #c0cbd1;
}

.container :global(.select-filter__menu) {
  border-radius: 15px;
  overflow: hidden;
  box-shadow: none;
  border: solid 1.5px #c0cbd1;
  z-index: 12;
}

.container :global(.select-filter__menu-list::-webkit-scrollbar) {
  width: 2px;
}

.container :global(.select-filter__option) {
  cursor: pointer;
  font-family: var(--archivo);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.35px;
  color: #687c97;
}

.container :global(.select-filter__option--is-focused) {
  background-color: rgba(126, 153, 224, 0.1);
}

.container :global(.select-filter__option--is-selected) {
  background-color: rgba(126, 153, 224, 0.3);
  font-weight: 700;
}

.container :global(.select-filter__single-value) {
  font-size: 14.5px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #687c97;
}

/* .container :global(.select-filter__multi-value) {
  display: none;
} */

.container :global(.select-filter__placeholder) {
  font-family: var(--archivo);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #687c97;
  text-transform: uppercase;
}

.container .displayName {
  font-family: var(--archivo);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #687c97;
  text-transform: uppercase;
}

.container :global(.select-filter__clear-indicator) {
  display: none;
}

.nameContainer {
  display: flex;
  align-items: center;
}

.container .name {
  font-family: var(--archivo);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #687c97;
  text-transform: uppercase;
}

.select-filter
  .select-filter__control--is-focused
  .select-filter__name--isSearchable {
  display: none;
}

.multiValue {
  font-family: var(--archivo);
  font-size: 12.5px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #222b45;
}

.multiValue::after {
  content: ",";
  margin-left: 2px;
}

.multiValue:last-of-type::after {
  content: "";
}

.container :global(.select-filter__value-container) {
  padding-left: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: var(--archivo);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #687c97;
  width: 100px;
  white-space: nowrap;
  text-transform: uppercase;
  display: flex;
  flex-wrap: nowrap;
}

.filterName {
  position: absolute;
  top: 0;
  z-index: 3;
  background: white;
  left: 16px;
  font-family: var(--archivo);
  font-size: 12px;
  color: #222b45;
  transform: translateY(-8px);
  text-transform: uppercase;
}

.placeholder {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  font-family: var(--archivo);
  font-size: 14px;
  font-weight: 500;
  color: #687c97;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out !important;
}

.placeholderActive {
  font-family: var(--archivo);
  font-size: 12px;
  font-weight: 600;
  color: #222b45;
  top: 0;
  background-color: #ffffff;
  padding: 0 4px;
}

.containerActive :global(.select-filter__control) {
  border: 2px solid #222b45 !important;
  transition: all 0.3s ease-in-out !important;
}

.arrow {
  color: #687c97;
  width: 20px;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.container :global(.select-filter__indicator) {
  opacity: 0;
}

.container :global(.select-filter__indicators) {
  opacity: 0 !important;
  z-index: 2;
}

.container :global(.select-filter__multi-value) {
  background-color: transparent;
}

.container :global(.select-filter__multi-value__label) {
  padding: 0;
  font-family: var(--archivo);
  font-size: 12.5px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #222b45;
}

.container :global(.select-filter__multi-value__remove) {
  display: none;
}
