.mySection {
  display: grid;
  grid-template-rows: 1fr 1fr;
  row-gap: 1rem;
}

.mySectionBox {
  border-radius: 10px;
  box-shadow: 0 20px 30px 0 rgb(187 181 198 / 58%);
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 1rem;
}

.mySectionBoxValue {
  font-family: var(--archivo);
  font-size: 40px;
  color: #222b45;
}

.mySectionBoxLeft {
  border-right: 1px solid rgb(112, 112, 112, 0.3);
  flex: 1;
  margin-right: 1rem;
}

.mySectionBoxLabel {
  font-family: var(--archivo);
  font-size: 13px;
  font-weight: 600;
  color: #687c97;
}

.mySectionBoxView {
  border-radius: 5px;
  box-shadow: 0 5px 10px 0 rgba(92, 170, 180, 0.29);
  background-color: #687c97;
  padding: 4px 10px;
  margin-top: 7px;
  font-family: var(--archivo);
  font-size: 11.5px;
  font-weight: bold;
  letter-spacing: 0.58px;
  text-align: center;
  color: #fff;
  transition: all 0.3s ease-in;
}

.mySectionBox:hover .mySectionBoxView {
  background-color: #32dea5;
}

.lastActivity {
  box-shadow: 0 20px 30px 0 rgb(187 181 198 / 58%);
  background-color: rgba(255, 255, 255, 0.3);
  padding: 1rem;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  display: block;
}

.lastActivityTop {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(112, 112, 112, 0.5);
  padding-bottom: 12px;
}

.lastActivityTitle {
  font-family: var(--archivo);
  font-size: 13px;
  font-weight: 600;
  color: #687c97;
  margin-left: 12px;
}

.lastActivityCircle {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #687c97;
}

.lastActivityUpdate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  margin-top: 4px;
}

.lastActivityUpdateIcon {
  width: 22px;
  height: 20px;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
}

.lastActivityUpdateText {
  font-family: var(--archivo);
  font-size: 13px;
  color: #687c97;
  padding-left: 12px;
  flex: 1;
}

.lastActivityUpdates {
  display: block;
}

.noActivity {
  font-family: var(--archivo);
  font-size: 13px;
  color: #687c97;
  text-align: center;
  margin-top: 25px;
}

.lastActivityUpdateDate {
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.56);
  padding: 4px 8px;
  font-family: var(--archivo);
  font-size: 11px;
  font-weight: 600;
  color: #687c97;
}

.greenDot {
  border: solid 1px #fff;
  background-color: #00d986;
  width: 12px;
  height: 12px;
  display: inline-block;
  margin-bottom: -2px;
  margin-right: 4px;
  border-radius: 50%;
}

:global(.update-enter) {
  opacity: 0;
  height: 0;
  overflow: hidden;
}
:global(.update-enter-active) {
  opacity: 1;
  height: 30px;
  transition: height 400ms, opacity 400ms;
}
:global(.update-exit) {
  opacity: 1;
  height: 30px;
}
:global(.update-exit-active) {
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: height 400ms, opacity 400ms;
}

.filters {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.filter {
  border-radius: 30px;
  background-color: #fff;
  height: 22px;
  font-family: var(--archivo);
  font-size: 12.5px;
  letter-spacing: 0.63px;
  color: #687c97;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  margin-right: 8px;
  white-space: nowrap;
}
