.c-upcomingtender {
  border-radius: 0.9375rem;
  border: solid 0.0625rem #edf2f6;
  background-color: #fff;
  padding: 0.875rem 24px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  align-items: center;
}

.c-upcomingtender:last-of-type {
  margin-bottom: 0;
}

.c-upcomingtender > div:first-of-type {
  border-left: none;
}

.c-upcomingtender__main {
  flex: 1;
}

.c-upcomingtender__calendar {
  padding: 0 2rem;
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 32px;
}

.c-upcomingtender__calendar::before,
.c-upcomingtender__date::before {
  position: absolute;
  left: 0;
  height: 1.5rem;
  width: 0.125rem;
  content: "";
  background-color: #eaeef2;
}

.c-upcomingtender__calendar::after {
  position: absolute;
  right: 0;
  height: 1.5rem;
  width: 0.125rem;
  content: "";
  background-color: #eaeef2;
}

.c-upcomingtender__calendar__btn {
  font-family: var(--archivo);
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.0437rem;
  color: #687c97;
  background-color: transparent;
  display: flex;
  align-items: center;
  margin: 0 auto;
  white-space: nowrap;
}

.c-upcomingtender__calendar__icon {
  width: 1.25rem;
  margin-right: 0.25rem;
}

.c-upcomingtender__calendar__register {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: none;
  padding-left: 24px;
}

.c-upcomingtender__calendar__register button {
  height: 2.8125rem;
  width: 8.25rem;
  border-radius: 0.375rem;
  border: solid 0.0625rem #687c97;
  background-color: #fff;

  font-family: var(--archivo);
  font-size: 0.9375rem;
  font-weight: 600;
  letter-spacing: 0.0469rem;
  color: #222b45;

  margin: 0 auto;
  display: block;
}

.c-upcomingtender__registered {
  border-radius: 0.375rem;
  border: solid 0.0625rem transparent;
  background-color: transparent;
  font-family: var(--archivo);
  font-size: 0.875rem;
  font-weight: 600;
  color: #222b45;
  display: flex;
  background-color: #e8ebf2;
  align-items: center;
  justify-content: center;
  height: 2.8125rem;
  cursor: not-allowed;
  opacity: 0.5;
  width: 8.25rem;
}

.c-upcomingtender__registered svg {
  width: 1.25rem;
  margin-right: 0.25rem;
}

.c-upcomingtender__clock {
  width: 2.125rem;
  height: 2.125rem;
  background-color: rgba(234, 238, 242, 0.75);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-upcomingtender__clock > * {
  width: 1.25rem;
  color: #687c97;
}

.c-upcomingtender__date {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 32px;
  position: relative;
}

.c-upcomingtender__time {
  font-family: var(--archivo);
  font-size: 1.125rem;
  color: #687c97;
  margin: 0 16px;
}

.c-upcomingtender__name {
  font-family: var(--archivo);
  font-size: 1rem;
  font-weight: 600;
  color: #222b45;
  margin-bottom: 0.5rem;
  display: inline-block;
}

.c-upcomingtender__tender {
  font-family: var(--archivo);
  font-size: 1rem;
  color: rgba(104, 124, 151, 0.67);
  margin-right: 0.25rem;
}

.c-upcomingtender__filters {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  white-space: nowrap;
  flex-wrap: wrap;
  overflow: hidden;
  margin-top: -0.5rem;
}

.c-upcomingtender__filters > div {
  margin-right: 0.25rem;
  margin-top: 0.5rem;
}

.c-upcomingtender .ui-button {
  height: 2.8125rem;
}

.c-upcomingtender__actions {
  display: flex;
  align-items: center;
}

@media (max-width: 86.25rem) {
  .c-upcomingtender__calendar {
    margin-right: 16px;
    padding: 0 16px;
  }
  .c-upcomingtender__date {
    padding: 0 16px;
  }

  .c-upcomingtender__time {
    font-size: 1rem;
    margin: 8px;
  }

  .c-upcomingtender .ui-button {
    height: 2.8125rem;
    font-size: 0.875rem;
    width: 9.375rem;
  }
}

@media (max-width: 768px) {
  .c-upcomingtender {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .c-upcomingtender__filters {
    margin: -0.625rem 0 0 0;
    flex-wrap: wrap;
    overflow: hidden;
  }
  .c-upcomingtender .c-upcomingtender__filters > * {
    margin: 0.625rem 0.5rem 0 0 !important;
  }

  .c-upcomingtender__calendar {
    padding: 0;
    border: none;
  }

  .c-upcomingtender__date {
    padding: 0;
    border: none;
  }

  .c-upcomingtender__main {
    order: 2;
    margin: 0.5rem 0;
  }

  .c-upcomingtender__date {
    order: 1;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .c-upcomingtender__calendar {
    order: 4;
  }

  .c-upcomingtender .ui-button {
    order: 3;
    width: 100%;
    height: 2.5rem;
    margin-bottom: 0.5rem;
  }

  .c-upcomingtender__clock {
    display: none;
  }

  .c-upcomingtender__time {
    margin: 0 0.5rem;
  }

  .c-upcomingtender__time br {
    display: none;
  }

  .c-upcomingtender__calendar::before,
  .c-upcomingtender__calendar::after {
    display: none;
  }

  .c-upcomingtender__date::before,
  .c-upcomingtender__date::after {
    display: none;
  }

  .c-upcomingtender {
    align-items: unset;
  }
}
