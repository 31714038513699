.tender-page {
  display: block;
  background-color: #f9fbfd;
  min-height: calc(100% + 3rem);
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.tender-diamonds__table {
  --tableColumns: 0.102fr 0.07fr 0.07fr 0.07fr 0.07fr 0.07fr 0.07fr 0.1fr 0.1fr
    0.1fr 0.1fr auto;
  margin: 2rem;
}

.tender-diamonds__table .data-table__body-cell {
  text-transform: uppercase;
}

.tender-diamonds__summary {
  display: flex;
  align-items: center;
  padding: 1.5rem 2rem 0 2rem;
}

.tender-diamonds__summary-icon {
  width: 20px;
  margin-right: 1rem;
}

.tender-diamonds__summary-title {
  font-family: var(--archivo);
  font-size: 14px;
  font-weight: 600;
  color: #687c97;
}

.tender-diamonds__summary-number {
  font-size: 16px;
  margin: 0 2px;
}

.tender-page__filters {
  background-color: #fff;
  padding: var(--sectionPadding);
  border-top: solid 1px rgba(191, 200, 206, 0.42);
  border-bottom: solid 1px rgba(191, 200, 206, 0.42);
}

.tender-page__filters-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.tender-page__filters__header {
  display: flex;
  align-items: center;
}

.tender-page__filters__header-icon {
  color: #687c97;
  width: 18px;
}

.tender-page__filters__header-text {
  font-family: var(--archivo);
  font-size: 13px;
  font-weight: 600;
  color: #687c97;
  margin-left: 8px;
}

.tender-page__filters__clear {
  display: flex;
  align-items: center;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  border-radius: 30px;
  background-color: rgba(222, 230, 235, 0.31);
  cursor: pointer;
  max-height: 36px;
}

.tender-page__filters__clear-text {
  font-family: var(--archivo);
  font-size: 13px;
  font-weight: 600;
  color: #687c97;
}

.tender-page__filters__clear-icon {
  color: #687c97;
  width: 18px;
  margin-right: 4px;
}

.tender-page .filters {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  column-gap: 1.5rem;
  row-gap: 1.5rem;
}

.tender-page__header {
  min-height: 200px;
  background: rgb(239, 233, 249);
  background-image: url("../../assets/images/register-bg.jpg");
  background-repeat: repeat;
  background-position-y: top;
  padding: var(--sectionPadding);
}

.tender-page__header-top {
  display: flex;
  align-items: center;
}

.tender-page__header-top__diamond-circle {
  width: 50px;
  height: 50px;
  box-shadow: 0 15px 20px 0 rgba(168, 160, 185, 0.78);
  border: solid 1.5px #edf2f6;
  background-color: #492cbd;
  border-radius: 50%;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tender-page__header-top__name {
  font-family: var(--archivo);
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.48px;
  color: #222b45;
}

.tender-page__header-top__active-tender {
  font-family: var(--archivo);
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.48px;
  color: rgba(104, 124, 151, 0.67);
  padding-right: 8px;
}

.tender-page__header-top__dates {
  height: 31.1px;
  border-radius: 30px;
  border: solid 1px rgba(104, 124, 151, 0.24);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.5rem;
  padding: 0 1rem;
}

.tender-page__header-top__dates-icon {
  width: 20px;
  color: #687c97;
}

.tender-page__header-top__dates-range {
  font-family: var(--archivo);
  font-size: 13px;
  color: #687c97;
}

.tender-page__header-top__dates-title {
  font-family: var(--archivo);
  font-size: 13px;
  font-weight: 600;
  color: #687c97;
  margin: 0 8px;
}

.tender-page__header-bottom {
  margin-top: 2rem;
  display: grid;
  grid-template-columns:
    minmax(140px, 170px) minmax(140px, 170px) minmax(320px, 350px)
    minmax(320px, 350px);
  grid-template-rows: 145px;
  column-gap: 1rem;
}

.tender-page__detail-box {
  /* width: 150px; */
  box-shadow: 0 20px 30px 0 rgba(187, 181, 198, 0.58);
  background-color: rgba(255, 255, 255, 0.3);
  padding: 1rem;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tender-page__detail-box__icon-circle {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #687c97;
}

.tender-page__detail-box__title {
  font-family: var(--archivo);
  font-size: 40px;
  color: #222b45;
}

.tender-page__detail-box__subtitle {
  font-family: var(--archivo);
  font-size: 13px;
  font-weight: 600;
  color: #687c97;
}

.tender-page__event-timer {
  /* width: 316px; */
  box-shadow: 0 20px 30px 0 rgb(187 181 198 / 58%);
  background-color: rgba(255, 255, 255, 0.3);
  padding: 1rem;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tender-page__event-timer__top {
  display: flex;
  align-items: center;
}

.tender-page__event-timer__top-icon-circle {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #687c97;
}

.tender-page__event-timer__top-icon-circle > * {
  width: 18px;
}

.tender-page__event-timer__top-title {
  font-family: var(--archivo);
  font-size: 13px;
  font-weight: 600;
  color: #687c97;
  margin-left: 12px;
}

.tender-page__latest-activity {
  /* width: 316px; */
  box-shadow: 0 20px 30px 0 rgb(187 181 198 / 58%);
  background-color: rgba(255, 255, 255, 0.3);
  padding: 1rem;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}

.tender-page__latest-activity__top {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  border-bottom: solid 1.5px rgba(112, 112, 112, 0.17);
}

.tender-page__latest-activity__top-icon-circle {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #687c97;
}

.tender-page__latest-activity__top-icon-circle > * {
  width: 18px;
}

.tender-page__latest-activity__top-title {
  font-family: var(--archivo);
  font-size: 13px;
  font-weight: 600;
  color: #687c97;
  margin-left: 12px;
}

.tender-diamonds__table .tender-diamonds__table-diamond__image {
  width: 70%;
  height: 100%;
}

.tender-page__event-timer__progress .progress {
  height: 4px;
}

@media (max-width: 1280px) {
  .tender-page__header-bottom {
    max-width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1rem;
  }

  .tender-page__event-timer {
    grid-row: 1;
    grid-column: span 2;
  }

  .tender-page__last-activity {
    grid-column: span 2;
  }

  .tender-page .filters {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (max-width: 1024px) {
  .tender-page__header-bottom {
    max-width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 1rem;
  }

  .tender-page .filters {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 900px) {
  .tender-page .filters {
    grid-template-columns: repeat(2, 1fr);
  }

  .tender-page__header-bottom {
    margin-top: var(--sectionPadding);
  }
}

@media (max-width: 640px) {
  .tender-page .filters {
    grid-template-columns: repeat(1, 1fr);
  }

  .tender-page__header-top__active-tender,
  .tender-page__header-top__name {
    font-size: 20px;
  }

  .tender-page__header-top__diamond-circle {
    width: 40px;
    height: 40px;
    display: none;
  }

  .tender-page__header-top__diamond-circle svg {
    width: 80%;
  }

  .tender-page .ui-export {
    display: none;
  }
}
