.c-tendercard {
  border-radius: 0.9375rem;
  border: solid 0.0625rem #edf2f6;
  background-color: #fff;
  padding: 0.875rem 1.75rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.c-tendercard:last-of-type {
  margin: 0;
}

.c-tendercard__details {
  flex: 1;
}

.c-tendercard__tender {
  font-family: var(--archivo);
  font-size: 1rem;
  color: rgba(104, 124, 151, 0.67);
  margin-right: 0.25rem;
}

.c-tendercard__name {
  font-family: var(--archivo);
  font-size: 1rem;
  font-weight: 600;
  color: #222b45;
  display: inline-block;
}

.c-tendercard__filters {
  display: flex;
  width: fit-content;
  align-items: center;
  white-space: nowrap;
  text-transform: uppercase;
  margin-top: 0.5rem;
  flex-wrap: wrap;
  overflow: hidden;
}

.c-tendercard__filters > div:not(.c-tendercard__filters > div:last-of-type) {
  margin-right: 0.5rem;
}
.c-tendercard__filters > div {
  margin-bottom: 0.5rem;
}

.c-tendercard__title {
  font-family: var(--archivo);
  font-size: 0.9375rem;
  font-weight: 600;
  color: #687c97;
  display: block;
  margin-top: 0.5rem;
}

.c-tendercard__description {
  display: block;
  font-family: var(--archivo);
  font-size: 0.75rem;
  line-height: 1.58;
  color: rgba(104, 124, 151, 0.67);
  max-width: 25rem;
  margin-top: 0.625rem;
}

.c-tendercard__summary {
  display: flex;
  align-items: center;
}

.c-tendercard__summary__box {
  box-shadow: 0 0.9375rem 1.25rem 0 rgba(227, 227, 227, 0.84);
  border: solid 0.0625rem #e8ebf2;
  background-color: #fff;
  padding: 1rem 0.875rem;
  border-radius: 0.625rem;
  margin-right: 1rem;
  width: 10.625rem;
  height: 8.4375rem;
}

.c-tendercard__icon {
  width: 2.125rem;
  height: 2.125rem;
  border-radius: 50%;
  background-color: rgba(234, 238, 242, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-tendercard__icon svg {
  width: 1.25rem;
  color: #687c97;
}

.c-tendercard__summary__box__value {
  display: inline-block;
  font-family: var(--archivo);
  font-size: 1.125rem;
  color: #222b45;
  font-weight: 600;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.c-tendercard__summary__box__label {
  font-family: var(--archivo);
  font-size: 0.875rem;
  color: #687c97;
  display: flex;
  align-items: center;
}

.c-tendercard__countdown {
  border-radius: 0.6875rem;
  background-color: rgba(232, 235, 242, 0.25);
  padding: 0.75rem;
}

.c-tendercard__countdown .ui-progress {
  margin-top: 0.25rem;
}

.c-tendercard__right {
  position: relative;
  max-width: 23.75rem;
  width: 100%;
}
.c-tendercard__right .progress {
  height: 0.25rem;
  margin-top: 0.75rem;
}

.c-tendercard__actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  margin-top: 0.75rem;
}

.c-tendercard__actions button {
  height: 2.8125rem;
}

.c-tendercard__actions .c-tendercard__catalog,
.c-tendercard__actions .c-tendercard__enter {
  width: 100%;
}

.c-tendercard__catalog svg {
  margin-right: 0.25rem;
  width: 1.25rem;
}

.c-tendercard__flag {
  width: 1.875rem;
  margin-left: 0.5rem;
}

.c-tendercard__live {
  padding: 0.25rem 0.75rem;
  border-radius: 0.3125rem;
  background-color: #32dea5;
  position: absolute;
  top: -0.875rem;
  left: 50%;
  transform: translateX(-50%);

  font-family: var(--archivo);
  font-size: 0.8125rem;
  font-weight: bold;
  color: #fff;
}

.c-tendercard__next {
  padding: 0.25rem 0.75rem;
  border-radius: 0.3125rem;
  background-color: #222b45;
  position: absolute;
  top: -0.875rem;
  left: 50%;
  transform: translateX(-50%);

  font-family: var(--archivo);
  font-size: 0.8125rem;
  font-weight: bold;
  color: #fff;
}

.c-tendercard__register__container {
  width: 100%;
  height: 100%;
}

.c-tendercard__register {
  border-radius: 0.375rem;
  border: solid 0.0625rem #687c97;
  background-color: transparent;
  font-family: var(--archivo);
  font-size: 0.875rem;
  font-weight: 600;
  color: #222b45;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.c-tendercard__register svg {
  margin-right: 0.25rem;
  width: 1.25rem;
}

.c-tendercard__registered {
  border-radius: 0.375rem;
  border: solid 0.0625rem transparent;
  background-color: transparent;
  font-family: var(--archivo);
  font-size: 0.875rem;
  font-weight: 600;
  color: #222b45;
  display: flex;
  background-color: #e8ebf2;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: not-allowed;
  opacity: 0.5;
}

.c-tendercard__registered svg {
  margin-right: 0.25rem;
  width: 1.25rem;
}

@media (max-width: 48rem) {
  .c-tendercard {
    display: flex;
    flex-direction: column;
    padding: var(--sectionPadding);
    padding-top: calc(4.875rem + var(--sectionPadding));
    position: relative;
    align-items: unset;
  }

  .c-tendercard__right {
    position: unset;
  }

  .c-tendercard__details {
    flex: unset;
    margin-top: 0.75rem;
  }

  .c-tendercard__countdown {
    position: absolute;
    top: var(--sectionPadding);
    width: calc(100% - var(--sectionPadding) * 2);
    left: 50%;
    transform: translateX(-50%);
  }

  .c-tendercard__next {
    z-index: 1;
    top: 0.3125rem;
  }

  .c-tendercard__title {
    margin-top: 0.75rem;
  }

  .c-tendercard__summary {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 8.125rem;
    column-gap: 1rem;
    margin: 0;
  }

  .c-tendercard__summary__box {
    width: inherit;
    height: inherit;
    margin: 0;
  }

  .c-tendercard__actions {
    display: flex;
    flex-direction: column-reverse;
  }

  .c-tendercard__actions > button:last-of-type {
    margin-bottom: 1rem;
  }

  .c-tendercard__summary__box__label {
    font-size: 0.75rem;
  }

  .c-tendercard__tender {
    margin-bottom: 0.625rem;
  }

  .c-tendercard__filters {
    margin: -0.625rem 0 0 0;
    flex-wrap: wrap;
    overflow: hidden;
  }
  /* add a corresponding positive top margin to all flex items (all direct children of the flex container) */
  .c-tendercard__filters > * {
    margin: 0.625rem 0.5rem 0 0 !important;
  }

  .c-tendercard__summary__box > .c-tendercard__flag__container {
    display: none;
  }
}
