.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.box {
  display: flex;
  align-items: center;
}

.name {
  text-transform: capitalize;
  font-family: var(--archivo);
  font-size: 13px;
  color: #687c97;
}

.values {
  display: flex;
  align-items: center;
}

.value {
  margin: 0 4px;
  cursor: pointer;
}

.value:hover {
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #e6e6e6;
  background-color: #fff;
  transition: all 0.3s ease-in;
}

.value .remove {
  width: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.value .remove svg {
  min-width: 12px;
  width: 12px;
  height: 12px;
  min-height: 12px;
}

.value:hover .remove {
  width: 22px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}
