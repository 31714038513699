.container {
  position: relative;
}

.toggle {
  border-radius: 30px;
  outline-style: none;
  border: solid 1px #c0cbd1;
  box-shadow: none;
  height: 35px;
  min-height: unset;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  position: relative;
}

.containerActive .toggle {
  border: 2px solid #222b45 !important;
  transition: all 0.3s ease-in-out !important;
}

.toggleName {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  font-family: var(--archivo);
  font-size: 14px;
  font-weight: 500;
  color: #687c97;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out !important;
}

.toggleNameActive {
  font-family: var(--archivo);
  font-size: 12px;
  font-weight: 600;
  color: #222b45;
  top: 0;
  background-color: #ffffff;
  padding: 0 4px;
}

.toggleArrow {
  color: #687c97;
  width: 20px;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.dropdown {
  position: absolute;
  transform: translateY(8px);
  border-radius: 18px;
  border: solid 1.5px #c0cbd1;
  background-color: #ffffff;
  width: 100%;
  min-height: 30px;
  padding: 1rem;
  z-index: 100;
}

.fields {
  display: grid;
  grid-template-columns: 48% 48%;
  justify-content: space-between;
}

.fieldLabel {
  font-family: var(--archivo);
  font-size: 13px;
  letter-spacing: normal;
  color: #687c97;
}

.fieldInput {
  height: 32px;
  border-radius: 4px;
  border: solid 1px #687c97;
  background-color: #ffffff;
  margin-top: 8px;
  text-align: center;
  max-width: 100%;
  width: 100%;
  display: block;
}

.slider {
  margin-bottom: 1rem;
}

.container :global(.rc-slider-rail) {
  background-color: #687c97;
}

.container :global(.rc-slider-track) {
  background-color: #492cbd;
}

.container :global(.rc-slider-handle) {
  background-color: #492cbd;
  border: none !important;
  box-shadow: none !important;
}

.values {
  font-family: var(--archivo);
  font-size: 12.5px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #222b45;
  padding-left: 8px;
}
