.product-table {
  display: block;
  --tableTemplateRows: repeat(6, 22px);
}
.product-table__title {
  font-family: var(--archivo);
  font-size: 1rem;
  font-weight: 600;
  color: #687c97;
  margin-bottom: 1rem;
}

.product-table__grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: var(--tableTemplateRows);
  grid-auto-flow: row;
}

.product-table__grid-box {
  display: flex;
  align-items: center;
}

.product-table__grid-box__label {
  flex: 1;
  font-family: var(--archivo);
  font-size: 14px;
  letter-spacing: 0.55px;
  color: #687c97;
}
.product-table__grid-box__value {
  flex: 1;
  font-family: var(--archivo);
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.33px;
  color: #687c97;
  text-transform: uppercase;
}
