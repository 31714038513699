.short-details {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.short-details > * {
  margin-right: 8px;
  margin-bottom: 6px;
  text-transform: uppercase;
}
.short-details > *:last-of-type {
  margin-right: 0;
}
