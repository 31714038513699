@import url(https://fonts.googleapis.com/css2?family=Archivo:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
.c-upcomingtender {
  border-radius: 0.9375rem;
  border: solid 0.0625rem #edf2f6;
  background-color: #fff;
  padding: 0.875rem 24px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  align-items: center;
}

.c-upcomingtender:last-of-type {
  margin-bottom: 0;
}

.c-upcomingtender > div:first-of-type {
  border-left: none;
}

.c-upcomingtender__main {
  flex: 1 1;
}

.c-upcomingtender__calendar {
  padding: 0 2rem;
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 32px;
}

.c-upcomingtender__calendar::before,
.c-upcomingtender__date::before {
  position: absolute;
  left: 0;
  height: 1.5rem;
  width: 0.125rem;
  content: "";
  background-color: #eaeef2;
}

.c-upcomingtender__calendar::after {
  position: absolute;
  right: 0;
  height: 1.5rem;
  width: 0.125rem;
  content: "";
  background-color: #eaeef2;
}

.c-upcomingtender__calendar__btn {
  font-family: var(--archivo);
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.0437rem;
  color: #687c97;
  background-color: transparent;
  display: flex;
  align-items: center;
  margin: 0 auto;
  white-space: nowrap;
}

.c-upcomingtender__calendar__icon {
  width: 1.25rem;
  margin-right: 0.25rem;
}

.c-upcomingtender__calendar__register {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: none;
  padding-left: 24px;
}

.c-upcomingtender__calendar__register button {
  height: 2.8125rem;
  width: 8.25rem;
  border-radius: 0.375rem;
  border: solid 0.0625rem #687c97;
  background-color: #fff;

  font-family: var(--archivo);
  font-size: 0.9375rem;
  font-weight: 600;
  letter-spacing: 0.0469rem;
  color: #222b45;

  margin: 0 auto;
  display: block;
}

.c-upcomingtender__registered {
  border-radius: 0.375rem;
  border: solid 0.0625rem transparent;
  background-color: transparent;
  font-family: var(--archivo);
  font-size: 0.875rem;
  font-weight: 600;
  color: #222b45;
  display: flex;
  background-color: #e8ebf2;
  align-items: center;
  justify-content: center;
  height: 2.8125rem;
  cursor: not-allowed;
  opacity: 0.5;
  width: 8.25rem;
}

.c-upcomingtender__registered svg {
  width: 1.25rem;
  margin-right: 0.25rem;
}

.c-upcomingtender__clock {
  width: 2.125rem;
  height: 2.125rem;
  background-color: rgba(234, 238, 242, 0.75);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-upcomingtender__clock > * {
  width: 1.25rem;
  color: #687c97;
}

.c-upcomingtender__date {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 32px;
  position: relative;
}

.c-upcomingtender__time {
  font-family: var(--archivo);
  font-size: 1.125rem;
  color: #687c97;
  margin: 0 16px;
}

.c-upcomingtender__name {
  font-family: var(--archivo);
  font-size: 1rem;
  font-weight: 600;
  color: #222b45;
  margin-bottom: 0.5rem;
  display: inline-block;
}

.c-upcomingtender__tender {
  font-family: var(--archivo);
  font-size: 1rem;
  color: rgba(104, 124, 151, 0.67);
  margin-right: 0.25rem;
}

.c-upcomingtender__filters {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  white-space: nowrap;
  flex-wrap: wrap;
  overflow: hidden;
  margin-top: -0.5rem;
}

.c-upcomingtender__filters > div {
  margin-right: 0.25rem;
  margin-top: 0.5rem;
}

.c-upcomingtender .ui-button {
  height: 2.8125rem;
}

.c-upcomingtender__actions {
  display: flex;
  align-items: center;
}

@media (max-width: 86.25rem) {
  .c-upcomingtender__calendar {
    margin-right: 16px;
    padding: 0 16px;
  }
  .c-upcomingtender__date {
    padding: 0 16px;
  }

  .c-upcomingtender__time {
    font-size: 1rem;
    margin: 8px;
  }

  .c-upcomingtender .ui-button {
    height: 2.8125rem;
    font-size: 0.875rem;
    width: 9.375rem;
  }
}

@media (max-width: 768px) {
  .c-upcomingtender {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .c-upcomingtender__filters {
    margin: -0.625rem 0 0 0;
    flex-wrap: wrap;
    overflow: hidden;
  }
  .c-upcomingtender .c-upcomingtender__filters > * {
    margin: 0.625rem 0.5rem 0 0 !important;
  }

  .c-upcomingtender__calendar {
    padding: 0;
    border: none;
  }

  .c-upcomingtender__date {
    padding: 0;
    border: none;
  }

  .c-upcomingtender__main {
    order: 2;
    margin: 0.5rem 0;
  }

  .c-upcomingtender__date {
    order: 1;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .c-upcomingtender__calendar {
    order: 4;
  }

  .c-upcomingtender .ui-button {
    order: 3;
    width: 100%;
    height: 2.5rem;
    margin-bottom: 0.5rem;
  }

  .c-upcomingtender__clock {
    display: none;
  }

  .c-upcomingtender__time {
    margin: 0 0.5rem;
  }

  .c-upcomingtender__time br {
    display: none;
  }

  .c-upcomingtender__calendar::before,
  .c-upcomingtender__calendar::after {
    display: none;
  }

  .c-upcomingtender__date::before,
  .c-upcomingtender__date::after {
    display: none;
  }

  .c-upcomingtender {
    align-items: unset;
  }
}

.c-tendercard {
  border-radius: 0.9375rem;
  border: solid 0.0625rem #edf2f6;
  background-color: #fff;
  padding: 0.875rem 1.75rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.c-tendercard:last-of-type {
  margin: 0;
}

.c-tendercard__details {
  flex: 1 1;
}

.c-tendercard__tender {
  font-family: var(--archivo);
  font-size: 1rem;
  color: rgba(104, 124, 151, 0.67);
  margin-right: 0.25rem;
}

.c-tendercard__name {
  font-family: var(--archivo);
  font-size: 1rem;
  font-weight: 600;
  color: #222b45;
  display: inline-block;
}

.c-tendercard__filters {
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  white-space: nowrap;
  text-transform: uppercase;
  margin-top: 0.5rem;
  flex-wrap: wrap;
  overflow: hidden;
}

.c-tendercard__filters > div:not(.c-tendercard__filters > div:last-of-type) {
  margin-right: 0.5rem;
}
.c-tendercard__filters > div {
  margin-bottom: 0.5rem;
}

.c-tendercard__title {
  font-family: var(--archivo);
  font-size: 0.9375rem;
  font-weight: 600;
  color: #687c97;
  display: block;
  margin-top: 0.5rem;
}

.c-tendercard__description {
  display: block;
  font-family: var(--archivo);
  font-size: 0.75rem;
  line-height: 1.58;
  color: rgba(104, 124, 151, 0.67);
  max-width: 25rem;
  margin-top: 0.625rem;
}

.c-tendercard__summary {
  display: flex;
  align-items: center;
}

.c-tendercard__summary__box {
  box-shadow: 0 0.9375rem 1.25rem 0 rgba(227, 227, 227, 0.84);
  border: solid 0.0625rem #e8ebf2;
  background-color: #fff;
  padding: 1rem 0.875rem;
  border-radius: 0.625rem;
  margin-right: 1rem;
  width: 10.625rem;
  height: 8.4375rem;
}

.c-tendercard__icon {
  width: 2.125rem;
  height: 2.125rem;
  border-radius: 50%;
  background-color: rgba(234, 238, 242, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-tendercard__icon svg {
  width: 1.25rem;
  color: #687c97;
}

.c-tendercard__summary__box__value {
  display: inline-block;
  font-family: var(--archivo);
  font-size: 1.125rem;
  color: #222b45;
  font-weight: 600;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.c-tendercard__summary__box__label {
  font-family: var(--archivo);
  font-size: 0.875rem;
  color: #687c97;
  display: flex;
  align-items: center;
}

.c-tendercard__countdown {
  border-radius: 0.6875rem;
  background-color: rgba(232, 235, 242, 0.25);
  padding: 0.75rem;
}

.c-tendercard__countdown .ui-progress {
  margin-top: 0.25rem;
}

.c-tendercard__right {
  position: relative;
  max-width: 23.75rem;
  width: 100%;
}
.c-tendercard__right .progress {
  height: 0.25rem;
  margin-top: 0.75rem;
}

.c-tendercard__actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  column-gap: 1rem;
  margin-top: 0.75rem;
}

.c-tendercard__actions button {
  height: 2.8125rem;
}

.c-tendercard__actions .c-tendercard__catalog,
.c-tendercard__actions .c-tendercard__enter {
  width: 100%;
}

.c-tendercard__catalog svg {
  margin-right: 0.25rem;
  width: 1.25rem;
}

.c-tendercard__flag {
  width: 1.875rem;
  margin-left: 0.5rem;
}

.c-tendercard__live {
  padding: 0.25rem 0.75rem;
  border-radius: 0.3125rem;
  background-color: #32dea5;
  position: absolute;
  top: -0.875rem;
  left: 50%;
  transform: translateX(-50%);

  font-family: var(--archivo);
  font-size: 0.8125rem;
  font-weight: bold;
  color: #fff;
}

.c-tendercard__next {
  padding: 0.25rem 0.75rem;
  border-radius: 0.3125rem;
  background-color: #222b45;
  position: absolute;
  top: -0.875rem;
  left: 50%;
  transform: translateX(-50%);

  font-family: var(--archivo);
  font-size: 0.8125rem;
  font-weight: bold;
  color: #fff;
}

.c-tendercard__register__container {
  width: 100%;
  height: 100%;
}

.c-tendercard__register {
  border-radius: 0.375rem;
  border: solid 0.0625rem #687c97;
  background-color: transparent;
  font-family: var(--archivo);
  font-size: 0.875rem;
  font-weight: 600;
  color: #222b45;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.c-tendercard__register svg {
  margin-right: 0.25rem;
  width: 1.25rem;
}

.c-tendercard__registered {
  border-radius: 0.375rem;
  border: solid 0.0625rem transparent;
  background-color: transparent;
  font-family: var(--archivo);
  font-size: 0.875rem;
  font-weight: 600;
  color: #222b45;
  display: flex;
  background-color: #e8ebf2;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: not-allowed;
  opacity: 0.5;
}

.c-tendercard__registered svg {
  margin-right: 0.25rem;
  width: 1.25rem;
}

@media (max-width: 48rem) {
  .c-tendercard {
    display: flex;
    flex-direction: column;
    padding: var(--sectionPadding);
    padding-top: calc(4.875rem + var(--sectionPadding));
    position: relative;
    align-items: unset;
  }

  .c-tendercard__right {
    position: unset;
  }

  .c-tendercard__details {
    flex: unset;
    margin-top: 0.75rem;
  }

  .c-tendercard__countdown {
    position: absolute;
    top: var(--sectionPadding);
    width: calc(100% - var(--sectionPadding) * 2);
    left: 50%;
    transform: translateX(-50%);
  }

  .c-tendercard__next {
    z-index: 1;
    top: 0.3125rem;
  }

  .c-tendercard__title {
    margin-top: 0.75rem;
  }

  .c-tendercard__summary {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 8.125rem;
    grid-column-gap: 1rem;
    column-gap: 1rem;
    margin: 0;
  }

  .c-tendercard__summary__box {
    width: inherit;
    height: inherit;
    margin: 0;
  }

  .c-tendercard__actions {
    display: flex;
    flex-direction: column-reverse;
  }

  .c-tendercard__actions > button:last-of-type {
    margin-bottom: 1rem;
  }

  .c-tendercard__summary__box__label {
    font-size: 0.75rem;
  }

  .c-tendercard__tender {
    margin-bottom: 0.625rem;
  }

  .c-tendercard__filters {
    margin: -0.625rem 0 0 0;
    flex-wrap: wrap;
    overflow: hidden;
  }
  /* add a corresponding positive top margin to all flex items (all direct children of the flex container) */
  .c-tendercard__filters > * {
    margin: 0.625rem 0.5rem 0 0 !important;
  }

  .c-tendercard__summary__box > .c-tendercard__flag__container {
    display: none;
  }
}

.product-image__container {
  position: relative;
  overflow: hidden;
  text-align: center;
  width: 100%;
  height: 100%;
  /* background-color: #f5f7f9; */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-image {
  max-width: 100%;
  max-height: 100%;
  opacity: 0 !important;
  transition: opacity 0.3s ease-in;
  object-fit: contain;
  z-index: -1;
  height: auto;
  width: auto;
  border-radius: 6px;
}

.product-image--visible {
  opacity: 1 !important;
  z-index: 1;
}

.product-image--360 {
  width: 100%;
  height: 100%;
}

.product-image__default {
  width: auto;
  height: auto;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0 !important;
  transition: opacity 0.3s ease-in;
  object-fit: cover;
  z-index: -1;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
  top: 50%;
  border-radius: 6px;
}

.product-image__default--visible {
  opacity: 1 !important;
  z-index: 1;
}

.short-details {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.short-details > * {
  margin-right: 8px;
  margin-bottom: 6px;
  text-transform: uppercase;
}
.short-details > *:last-of-type {
  margin-right: 0;
}

.tender-product__small-card {
  display: grid;
  grid-template-columns: 86px auto 46px;
  grid-template-rows: 82px;
  grid-column-gap: 1rem;
  column-gap: 1rem;
  cursor: pointer;
}

.tender-product__small-card__details {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-rows: auto auto;
  align-items: center;
  padding: 0.5rem 0;
  position: relative;
}

.tender-product__small-card__details-highest {
  display: flex;
  align-items: center;
}

.tender-product__small-card__details-highest > span {
  margin-right: 8px;
  font-family: var(--archivo);
  font-size: 12px;
  letter-spacing: 0.53px;
  color: #687c97;
}

.tender-product__small-card .wishlist::after {
  content: "";
  height: 50%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1.7px;
  background-color: #edf2f6;
  position: absolute;
}

.tender-product__small-card__btn {
  margin: auto;
  color: #fff;
}

.tender-product__small-card .wishlist {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  padding: 0 0 0 1rem;
}

.MyBids_container__2G24X {
  position: fixed;
  right: 0;
  bottom: 0;
  height: 100%;
  transform: translateY(calc(100% - var(--headerHeight)));
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 4;
  transition: transform 0.3s ease-in-out;
  width: 31.25rem;
}

.MyBids_containerActive__2rcsG {
  transform: translateY(0);
}

.MyBids_my-bids--open__VCrHc .MyBids_my-bids__toggle__1mLIq {
  box-shadow: none;
}

.MyBids_main__1jxmz {
  flex: 1 1;
  width: 100%;
  background-color: #ffffff;
  padding: var(--sectionPadding);
  box-shadow: -0.1875rem 0 1.25rem 0 rgb(187 181 198 / 84%);
  overflow: auto;
}

.MyBids_mainTop__2_k9t {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.MyBids_mainTopTitle__wcLK8 {
  font-family: var(--archivo);
  font-size: 1.4375rem;
  font-weight: 500;
  color: #222b45;
}

.MyBids_arrowContainer___qIum {
  width: 2rem;
  height: 2rem;
  border-radius: 0.375rem;
  background-color: #eaeef2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.MyBids_arrow__2782j {
  color: #687c97;
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
}

.MyBids_bidsTitle__vkGXi {
  display: flex;
  align-items: center;
  margin-top: 0.75rem;
  font-family: var(--archivo);
  font-size: 0.875rem;
  color: #687c97;
  border-bottom: solid 0.1062rem #edf2f6;
  padding-bottom: 16px;
}

.MyBids_bidsTitle__vkGXi > svg {
  margin-right: 0.25rem;
}

.MyBids_total__SO-o9 {
  flex: 1 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: var(--archivo);
  font-size: 0.875rem;
  color: #687c97;
}

.MyBids_total__SO-o9 span {
  font-family: var(--archivo);
  font-size: 1rem;
  color: #687c97;
  padding-left: 0.5rem;
  font-weight: bold;
}

.MyBids_noDataText__3mqp7 {
  font-family: var(--archivo);
  font-size: 1rem;
  color: #687c97;
  line-height: 1.88;
  margin-top: 0.75rem;
}

.MyBids_noBids__3a_p4 {
  font-family: var(--archivo);
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  color: #687c97;
  margin: 48px 0;
}

.MyBids_noBids__3a_p4 svg {
  margin-bottom: 0.5rem;
}

.MyBids_emptyBid__3CtHi {
  width: 27.6875rem;
  height: 5.625rem;
  padding: 0 0.75rem;
  border-radius: 0.625rem;
  background-color: rgba(234, 238, 242, 0.3);
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.MyBids_emptyBidImage__1sZKt {
  width: 4rem;
  height: 4rem;
  opacity: 0.6;
  border-radius: 0.4375rem;
  background-color: #eaeef2;
}

.MyBids_emptyBidDetails__1iQ-t {
  display: flex;
  flex-direction: column;
  height: 4rem;
  justify-content: space-between;
  flex: 1 1;
  margin: 0 16px;
}

.MyBids_emptyBidDetailsTop__3Uu_I {
  display: grid;
  grid-template-columns: 0.4fr 0.7fr 0.5fr 0.7fr 0.8fr;
  grid-template-rows: 1.375rem;
  grid-column-gap: 0.5rem;
  column-gap: 0.5rem;
}

.MyBids_emptyBidDetailsBottom__31OkG {
  opacity: 0.6;
  border-radius: 0.4375rem;
  background-color: #eaeef2;

  height: 1.375rem;
  width: 80%;
}

.MyBids_emptyBidDetailsTop__3Uu_I > div {
  opacity: 0.6;
  border-radius: 0.4375rem;
  background-color: #eaeef2;
}

.MyBids_emptyBidCircle__23s4g {
  width: 4rem;
  height: 4rem;
  opacity: 0.6;
  border-radius: 0.4375rem;
  background-color: #eaeef2;
  border-radius: 50%;
}

.MyBids_bidsList__3GXnV {
  margin-top: 16px;
}

.MyBids_bidsList__3GXnV > div:not(.MyBids_bidsList__3GXnV > div:last-of-type) {
  border-bottom: solid 0.1062rem #edf2f6;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
}

@media (max-width: 48rem) {
  .MyBids_container__2G24X {
    position: fixed;
    left: 0;
    width: 100%;
  }

  .MyBids_bidsList__3GXnV > div {
    grid-template-columns: 4.125rem auto 2.875rem;
  }

  .MyBids_container__2G24X .ui-price {
    font-size: 1rem;
  }
}

.MyBidsToggle_container__YEexD {
  width: 360px;
  height: var(--headerHeight);
  box-shadow: -3px 0 20px 0 rgba(187, 181, 198, 0.84);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 0 1.5rem;
  cursor: pointer;
  background-image: linear-gradient(118deg, #482cbb -26%, #231659 150%);
  color: #ffffff;
}

.MyBidsToggle_left__1fBpA {
  display: flex;
  align-items: center;
}

.MyBidsToggle_leftIcon__ss5U3 {
  color: #fff;
  fill: #fff;
  filter: invert();
}

.MyBidsToggle_leftText__2KDJj {
  font-family: var(--archivo);
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  margin-left: 8px;
}

.MyBidsToggle_right__1ffo5 {
  display: flex;
  align-items: center;
}

.MyBidsToggle_total__3HCRI {
  font-family: var(--archivo);
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.MyBidsToggle_arrowContainer__21-aO {
  width: 32px;
  height: 32px;
  border-radius: 6px;
  background-color: #eaeef2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.56);
}

.MyBidsToggle_arrow__YQXUJ {
  color: #ffffff;
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
}

.tender-page {
  display: block;
  background-color: #f9fbfd;
  min-height: calc(100% + 3rem);
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.tender-diamonds__table {
  --tableColumns: 0.102fr 0.07fr 0.07fr 0.07fr 0.07fr 0.07fr 0.07fr 0.1fr 0.1fr
    0.1fr 0.1fr auto;
  margin: 2rem;
}

.tender-diamonds__table .data-table__body-cell {
  text-transform: uppercase;
}

.tender-diamonds__summary {
  display: flex;
  align-items: center;
  padding: 1.5rem 2rem 0 2rem;
}

.tender-diamonds__summary-icon {
  width: 20px;
  margin-right: 1rem;
}

.tender-diamonds__summary-title {
  font-family: var(--archivo);
  font-size: 14px;
  font-weight: 600;
  color: #687c97;
}

.tender-diamonds__summary-number {
  font-size: 16px;
  margin: 0 2px;
}

.tender-page__filters {
  background-color: #fff;
  padding: var(--sectionPadding);
  border-top: solid 1px rgba(191, 200, 206, 0.42);
  border-bottom: solid 1px rgba(191, 200, 206, 0.42);
}

.tender-page__filters-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.tender-page__filters__header {
  display: flex;
  align-items: center;
}

.tender-page__filters__header-icon {
  color: #687c97;
  width: 18px;
}

.tender-page__filters__header-text {
  font-family: var(--archivo);
  font-size: 13px;
  font-weight: 600;
  color: #687c97;
  margin-left: 8px;
}

.tender-page__filters__clear {
  display: flex;
  align-items: center;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  border-radius: 30px;
  background-color: rgba(222, 230, 235, 0.31);
  cursor: pointer;
  max-height: 36px;
}

.tender-page__filters__clear-text {
  font-family: var(--archivo);
  font-size: 13px;
  font-weight: 600;
  color: #687c97;
}

.tender-page__filters__clear-icon {
  color: #687c97;
  width: 18px;
  margin-right: 4px;
}

.tender-page .filters {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-column-gap: 1.5rem;
  column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  row-gap: 1.5rem;
}

.tender-page__header {
  min-height: 200px;
  background: rgb(239, 233, 249);
  background-image: url(/static/media/register-bg.7a0b87d1.jpg);
  background-repeat: repeat;
  background-position-y: top;
  padding: var(--sectionPadding);
}

.tender-page__header-top {
  display: flex;
  align-items: center;
}

.tender-page__header-top__diamond-circle {
  width: 50px;
  height: 50px;
  box-shadow: 0 15px 20px 0 rgba(168, 160, 185, 0.78);
  border: solid 1.5px #edf2f6;
  background-color: #492cbd;
  border-radius: 50%;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tender-page__header-top__name {
  font-family: var(--archivo);
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.48px;
  color: #222b45;
}

.tender-page__header-top__active-tender {
  font-family: var(--archivo);
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.48px;
  color: rgba(104, 124, 151, 0.67);
  padding-right: 8px;
}

.tender-page__header-top__dates {
  height: 31.1px;
  border-radius: 30px;
  border: solid 1px rgba(104, 124, 151, 0.24);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.5rem;
  padding: 0 1rem;
}

.tender-page__header-top__dates-icon {
  width: 20px;
  color: #687c97;
}

.tender-page__header-top__dates-range {
  font-family: var(--archivo);
  font-size: 13px;
  color: #687c97;
}

.tender-page__header-top__dates-title {
  font-family: var(--archivo);
  font-size: 13px;
  font-weight: 600;
  color: #687c97;
  margin: 0 8px;
}

.tender-page__header-bottom {
  margin-top: 2rem;
  display: grid;
  grid-template-columns:
    minmax(140px, 170px) minmax(140px, 170px) minmax(320px, 350px)
    minmax(320px, 350px);
  grid-template-rows: 145px;
  grid-column-gap: 1rem;
  column-gap: 1rem;
}

.tender-page__detail-box {
  /* width: 150px; */
  box-shadow: 0 20px 30px 0 rgba(187, 181, 198, 0.58);
  background-color: rgba(255, 255, 255, 0.3);
  padding: 1rem;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tender-page__detail-box__icon-circle {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #687c97;
}

.tender-page__detail-box__title {
  font-family: var(--archivo);
  font-size: 40px;
  color: #222b45;
}

.tender-page__detail-box__subtitle {
  font-family: var(--archivo);
  font-size: 13px;
  font-weight: 600;
  color: #687c97;
}

.tender-page__event-timer {
  /* width: 316px; */
  box-shadow: 0 20px 30px 0 rgb(187 181 198 / 58%);
  background-color: rgba(255, 255, 255, 0.3);
  padding: 1rem;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tender-page__event-timer__top {
  display: flex;
  align-items: center;
}

.tender-page__event-timer__top-icon-circle {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #687c97;
}

.tender-page__event-timer__top-icon-circle > * {
  width: 18px;
}

.tender-page__event-timer__top-title {
  font-family: var(--archivo);
  font-size: 13px;
  font-weight: 600;
  color: #687c97;
  margin-left: 12px;
}

.tender-page__latest-activity {
  /* width: 316px; */
  box-shadow: 0 20px 30px 0 rgb(187 181 198 / 58%);
  background-color: rgba(255, 255, 255, 0.3);
  padding: 1rem;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}

.tender-page__latest-activity__top {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  border-bottom: solid 1.5px rgba(112, 112, 112, 0.17);
}

.tender-page__latest-activity__top-icon-circle {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #687c97;
}

.tender-page__latest-activity__top-icon-circle > * {
  width: 18px;
}

.tender-page__latest-activity__top-title {
  font-family: var(--archivo);
  font-size: 13px;
  font-weight: 600;
  color: #687c97;
  margin-left: 12px;
}

.tender-diamonds__table .tender-diamonds__table-diamond__image {
  width: 70%;
  height: 100%;
}

.tender-page__event-timer__progress .progress {
  height: 4px;
}

@media (max-width: 1280px) {
  .tender-page__header-bottom {
    max-width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 1rem;
    gap: 1rem;
  }

  .tender-page__event-timer {
    grid-row: 1;
    grid-column: span 2;
  }

  .tender-page__last-activity {
    grid-column: span 2;
  }

  .tender-page .filters {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (max-width: 1024px) {
  .tender-page__header-bottom {
    max-width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 1rem;
    gap: 1rem;
  }

  .tender-page .filters {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 900px) {
  .tender-page .filters {
    grid-template-columns: repeat(2, 1fr);
  }

  .tender-page__header-bottom {
    margin-top: var(--sectionPadding);
  }
}

@media (max-width: 640px) {
  .tender-page .filters {
    grid-template-columns: repeat(1, 1fr);
  }

  .tender-page__header-top__active-tender,
  .tender-page__header-top__name {
    font-size: 20px;
  }

  .tender-page__header-top__diamond-circle {
    width: 40px;
    height: 40px;
    display: none;
  }

  .tender-page__header-top__diamond-circle svg {
    width: 80%;
  }

  .tender-page .ui-export {
    display: none;
  }
}

.RangeFilter_container__sjk8O {
  position: relative;
}

.RangeFilter_toggle__grsQW {
  border-radius: 30px;
  outline-style: none;
  border: solid 1px #c0cbd1;
  box-shadow: none;
  height: 35px;
  min-height: unset;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  position: relative;
}

.RangeFilter_containerActive__2kuZj .RangeFilter_toggle__grsQW {
  border: 2px solid #222b45 !important;
  transition: all 0.3s ease-in-out !important;
}

.RangeFilter_toggleName__1XYib {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  font-family: var(--archivo);
  font-size: 14px;
  font-weight: 500;
  color: #687c97;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out !important;
}

.RangeFilter_toggleNameActive__8U7UE {
  font-family: var(--archivo);
  font-size: 12px;
  font-weight: 600;
  color: #222b45;
  top: 0;
  background-color: #ffffff;
  padding: 0 4px;
}

.RangeFilter_toggleArrow__ywlwW {
  color: #687c97;
  width: 20px;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.RangeFilter_dropdown__2LT_M {
  position: absolute;
  transform: translateY(8px);
  border-radius: 18px;
  border: solid 1.5px #c0cbd1;
  background-color: #ffffff;
  width: 100%;
  min-height: 30px;
  padding: 1rem;
  z-index: 100;
}

.RangeFilter_fields__3K-z8 {
  display: grid;
  grid-template-columns: 48% 48%;
  justify-content: space-between;
}

.RangeFilter_fieldLabel__3jL2L {
  font-family: var(--archivo);
  font-size: 13px;
  letter-spacing: normal;
  color: #687c97;
}

.RangeFilter_fieldInput__3tH70 {
  height: 32px;
  border-radius: 4px;
  border: solid 1px #687c97;
  background-color: #ffffff;
  margin-top: 8px;
  text-align: center;
  max-width: 100%;
  width: 100%;
  display: block;
}

.RangeFilter_slider__3GqMe {
  margin-bottom: 1rem;
}

.RangeFilter_container__sjk8O .rc-slider-rail {
  background-color: #687c97;
}

.RangeFilter_container__sjk8O .rc-slider-track {
  background-color: #492cbd;
}

.RangeFilter_container__sjk8O .rc-slider-handle {
  background-color: #492cbd;
  border: none !important;
  box-shadow: none !important;
}

.RangeFilter_values__11vST {
  font-family: var(--archivo);
  font-size: 12.5px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #222b45;
  padding-left: 8px;
}

.SelectFilter_container__21KxU .select-filter__indicator {
  color: hsl(0, 0%, 80%);
  padding: 0 8px;
}

.SelectFilter_container__21KxU .select-filter__indicator-separator {
  display: none;
}

.SelectFilter_container__21KxU .select-filter__control {
  border-radius: 30px;
  outline-style: none;
  border: solid 1.5px #c0cbd1;
  box-shadow: none;
  height: 35px;
  min-height: unset;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.SelectFilter_container__21KxU .select-filter__control--is-focused,
.SelectFilter_container__21KxU .select-filter__control--menu-is-open,
.SelectFilter_container__21KxU .select-filter__control:hover {
  border: solid 1.5px #c0cbd1;
}

.SelectFilter_container__21KxU .select-filter__menu {
  border-radius: 15px;
  overflow: hidden;
  box-shadow: none;
  border: solid 1.5px #c0cbd1;
  z-index: 12;
}

.SelectFilter_container__21KxU .select-filter__menu-list::-webkit-scrollbar {
  width: 2px;
}

.SelectFilter_container__21KxU .select-filter__option {
  cursor: pointer;
  font-family: var(--archivo);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.35px;
  color: #687c97;
}

.SelectFilter_container__21KxU .select-filter__option--is-focused {
  background-color: rgba(126, 153, 224, 0.1);
}

.SelectFilter_container__21KxU .select-filter__option--is-selected {
  background-color: rgba(126, 153, 224, 0.3);
  font-weight: 700;
}

.SelectFilter_container__21KxU .select-filter__single-value {
  font-size: 14.5px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #687c97;
}

/* .container :global(.select-filter__multi-value) {
  display: none;
} */

.SelectFilter_container__21KxU .select-filter__placeholder {
  font-family: var(--archivo);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #687c97;
  text-transform: uppercase;
}

.SelectFilter_container__21KxU .SelectFilter_displayName__cQYZ_ {
  font-family: var(--archivo);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #687c97;
  text-transform: uppercase;
}

.SelectFilter_container__21KxU .select-filter__clear-indicator {
  display: none;
}

.SelectFilter_nameContainer__NV281 {
  display: flex;
  align-items: center;
}

.SelectFilter_container__21KxU .SelectFilter_name__1WYBO {
  font-family: var(--archivo);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #687c97;
  text-transform: uppercase;
}

.SelectFilter_select-filter__1u6No
  .SelectFilter_select-filter__control--is-focused__3H8DX
  .SelectFilter_select-filter__name--isSearchable__KxgNb {
  display: none;
}

.SelectFilter_multiValue__1DJiz {
  font-family: var(--archivo);
  font-size: 12.5px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #222b45;
}

.SelectFilter_multiValue__1DJiz::after {
  content: ",";
  margin-left: 2px;
}

.SelectFilter_multiValue__1DJiz:last-of-type::after {
  content: "";
}

.SelectFilter_container__21KxU .select-filter__value-container {
  padding-left: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: var(--archivo);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #687c97;
  width: 100px;
  white-space: nowrap;
  text-transform: uppercase;
  display: flex;
  flex-wrap: nowrap;
}

.SelectFilter_filterName__drHCJ {
  position: absolute;
  top: 0;
  z-index: 3;
  background: white;
  left: 16px;
  font-family: var(--archivo);
  font-size: 12px;
  color: #222b45;
  transform: translateY(-8px);
  text-transform: uppercase;
}

.SelectFilter_placeholder__2ftuU {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  font-family: var(--archivo);
  font-size: 14px;
  font-weight: 500;
  color: #687c97;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out !important;
}

.SelectFilter_placeholderActive__2qSyI {
  font-family: var(--archivo);
  font-size: 12px;
  font-weight: 600;
  color: #222b45;
  top: 0;
  background-color: #ffffff;
  padding: 0 4px;
}

.SelectFilter_containerActive__wxjSt .select-filter__control {
  border: 2px solid #222b45 !important;
  transition: all 0.3s ease-in-out !important;
}

.SelectFilter_arrow__3HXL2 {
  color: #687c97;
  width: 20px;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.SelectFilter_container__21KxU .select-filter__indicator {
  opacity: 0;
}

.SelectFilter_container__21KxU .select-filter__indicators {
  opacity: 0 !important;
  z-index: 2;
}

.SelectFilter_container__21KxU .select-filter__multi-value {
  background-color: transparent;
}

.SelectFilter_container__21KxU .select-filter__multi-value__label {
  padding: 0;
  font-family: var(--archivo);
  font-size: 12.5px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #222b45;
}

.SelectFilter_container__21KxU .select-filter__multi-value__remove {
  display: none;
}

.TenderHeader_mySection__RSM-8 {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.TenderHeader_mySectionBox__3ea1k {
  border-radius: 10px;
  box-shadow: 0 20px 30px 0 rgb(187 181 198 / 58%);
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 1rem;
}

.TenderHeader_mySectionBoxValue__24OXt {
  font-family: var(--archivo);
  font-size: 40px;
  color: #222b45;
}

.TenderHeader_mySectionBoxLeft__3f4sQ {
  border-right: 1px solid rgb(112, 112, 112, 0.3);
  flex: 1 1;
  margin-right: 1rem;
}

.TenderHeader_mySectionBoxLabel__3er3h {
  font-family: var(--archivo);
  font-size: 13px;
  font-weight: 600;
  color: #687c97;
}

.TenderHeader_mySectionBoxView__1IBZ3 {
  border-radius: 5px;
  box-shadow: 0 5px 10px 0 rgba(92, 170, 180, 0.29);
  background-color: #687c97;
  padding: 4px 10px;
  margin-top: 7px;
  font-family: var(--archivo);
  font-size: 11.5px;
  font-weight: bold;
  letter-spacing: 0.58px;
  text-align: center;
  color: #fff;
  transition: all 0.3s ease-in;
}

.TenderHeader_mySectionBox__3ea1k:hover .TenderHeader_mySectionBoxView__1IBZ3 {
  background-color: #32dea5;
}

.TenderHeader_lastActivity__9XYqC {
  box-shadow: 0 20px 30px 0 rgb(187 181 198 / 58%);
  background-color: rgba(255, 255, 255, 0.3);
  padding: 1rem;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  display: block;
}

.TenderHeader_lastActivityTop__4i8Ff {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(112, 112, 112, 0.5);
  padding-bottom: 12px;
}

.TenderHeader_lastActivityTitle__2QVb6 {
  font-family: var(--archivo);
  font-size: 13px;
  font-weight: 600;
  color: #687c97;
  margin-left: 12px;
}

.TenderHeader_lastActivityCircle__3aBd7 {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #687c97;
}

.TenderHeader_lastActivityUpdate__AtDeF {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  margin-top: 4px;
}

.TenderHeader_lastActivityUpdateIcon__18ice {
  width: 22px;
  height: 20px;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
}

.TenderHeader_lastActivityUpdateText__LuVR3 {
  font-family: var(--archivo);
  font-size: 13px;
  color: #687c97;
  padding-left: 12px;
  flex: 1 1;
}

.TenderHeader_lastActivityUpdates__o3sLJ {
  display: block;
}

.TenderHeader_noActivity__1zyFA {
  font-family: var(--archivo);
  font-size: 13px;
  color: #687c97;
  text-align: center;
  margin-top: 25px;
}

.TenderHeader_lastActivityUpdateDate__2NFuw {
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.56);
  padding: 4px 8px;
  font-family: var(--archivo);
  font-size: 11px;
  font-weight: 600;
  color: #687c97;
}

.TenderHeader_greenDot__11CQp {
  border: solid 1px #fff;
  background-color: #00d986;
  width: 12px;
  height: 12px;
  display: inline-block;
  margin-bottom: -2px;
  margin-right: 4px;
  border-radius: 50%;
}

.update-enter {
  opacity: 0;
  height: 0;
  overflow: hidden;
}
.update-enter-active {
  opacity: 1;
  height: 30px;
  transition: height 400ms, opacity 400ms;
}
.update-exit {
  opacity: 1;
  height: 30px;
}
.update-exit-active {
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: height 400ms, opacity 400ms;
}

.TenderHeader_filters__22yOG {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.TenderHeader_filter__2BAK9 {
  border-radius: 30px;
  background-color: #fff;
  height: 22px;
  font-family: var(--archivo);
  font-size: 12.5px;
  letter-spacing: 0.63px;
  color: #687c97;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  margin-right: 8px;
  white-space: nowrap;
}

.TenderProducts_container__2Fscy {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  /* margin-bottom: 5rem; */
  padding-bottom: 5rem;
}

.TenderProducts_top__1NYrZ {
  display: flex;
  align-items: center;
  padding: var(--sectionPadding);
}

.TenderProducts_summary__L2RzM {
  display: flex;
  align-items: center;
}

.TenderProducts_summaryIcon__3L7Zj {
  width: 20px;
  margin-right: 0.5rem;
  color: #687c97;
}

.TenderProducts_summaryTitle__hj6ki {
  font-family: var(--archivo);
  font-size: 14px;
  font-weight: 600;
  color: #687c97;
}

.TenderProducts_summaryNumber__32ASk {
  font-size: 16px;
  margin: 0 2px;
}

.TenderProducts_container__2Fscy .ui-table__container {
  --columns: minmax(50px, 70px) minmax(90px, 90px)
    repeat(10, minmax(120px, 0.6fr)) minmax(130px, 1fr);
  width: calc(100% - var(--sectionPadding) * 2);
  margin: 0 auto;
}

.TenderProducts_model__24eGN,
.TenderProducts_carat__20AIe,
.TenderProducts_color__31uT7,
.TenderProducts_clarity__2tA31,
.TenderProducts_lab__2NGCt,
.TenderProducts_fluor__1vXyV {
  text-transform: uppercase;
}

.TenderProducts_filtersSummary__3eGjU {
  padding: 0 var(--sectionPadding);
  margin-top: -2rem;
  margin-bottom: 1rem;
  overflow: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.TenderProducts_reservePrice__7zGWN {
  display: flex;
  align-items: center;
}

.TenderProducts_reservePriceTotal__3-xsZ {
  font-size: 13px;

  position: relative;
  top: 1px;
  left: -8px;
}

.TenderProducts_line__1XhVb {
  flex: 1 1;
  height: 2px;
  background-color: #eaeef2;
  margin: 0 1.5rem;
}

.TenderProducts_selected__8EGsp {
  padding: 11px 20.4px 11px 23.1px;
  border-radius: 30px;
  background-color: rgba(222, 230, 235, 0.31);
  font-family: var(--archivo);
  font-size: 13px;
  font-weight: 600;
  color: #687c97;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in;
  margin-left: 10px;
}

.TenderProducts_selectedActive__3WDDY {
  color: #fff;
  background-color: var(--green);
}

.TenderProducts_selectedIcon__3r0Ii {
  margin-right: 6px;
  position: relative;
  top: -1px;
  width: 18px;
}

.TenderProducts_wishlist__zl8vB {
  width: 100%;
  height: 100%;
  background: transparent;
}

.TenderProducts_noData__373Za {
  margin-top: 4rem;
}

.TenderProducts_export__1EUoL {
  display: flex;
  align-items: center;
  outline-style: none;
  text-decoration: none;
  border-right: solid 1.5px rgba(192, 203, 209, 0.28);
  border-left: solid 1.5px rgba(192, 203, 209, 0.28);
  padding: 0 12px;
  margin: 0 12px;
}

.TenderProducts_exportText__1p2tE {
  font-family: var(--archivo);
  font-size: 13px;
  font-weight: 600;
  color: #687c97;
}

.TenderProducts_exportIcon__1tp_K {
  width: 20px;
  color: #687c97;
  margin-right: 4px;
}

.TenderProducts_table__3fmFc .pagination {
  margin: 0 2rem;
}

.TenderProducts_container__2Fscy .pagination {
  margin: 0 2rem;
}

.TenderProducts_container__2Fscy .no-data {
  width: calc(100% - 4rem);
  margin: 1rem auto;
}

.TenderProducts_container__2Fscy .ui-table__pagination {
  padding: 0 calc(var(--sectionPadding) / 2);
}

@media (max-width: 640px) {
  .TenderProducts_selected__8EGsp {
    width: 38px;
    height: 38px;
    min-width: 38px;
    min-height: 38px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: #ffffff;
    margin: 0;
  }

  .TenderProducts_selectedActive__3WDDY {
    background-color: var(--green);
  }

  .TenderProducts_selected__8EGsp span {
    display: none;
  }

  .TenderProducts_selected__8EGsp svg {
    margin: 0;
  }

  .TenderProducts_summaryTitle__hj6ki {
    white-space: nowrap;
  }

  .TenderProducts_filtersSummary__3eGjU {
    margin-top: 0;
    flex-wrap: nowrap !important;
  }
}

.FiltersSummary_container__7xkeu {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.FiltersSummary_box__2IgVL {
  display: flex;
  align-items: center;
}

.FiltersSummary_name__2am43 {
  text-transform: capitalize;
  font-family: var(--archivo);
  font-size: 13px;
  color: #687c97;
}

.FiltersSummary_values__4tit7 {
  display: flex;
  align-items: center;
}

.FiltersSummary_value__kY48b {
  margin: 0 4px;
  cursor: pointer;
}

.FiltersSummary_value__kY48b:hover {
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #e6e6e6;
  background-color: #fff;
  transition: all 0.3s ease-in;
}

.FiltersSummary_value__kY48b .FiltersSummary_remove__IQcb5 {
  width: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.FiltersSummary_value__kY48b .FiltersSummary_remove__IQcb5 svg {
  min-width: 12px;
  width: 12px;
  height: 12px;
  min-height: 12px;
}

.FiltersSummary_value__kY48b:hover .FiltersSummary_remove__IQcb5 {
  width: 22px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.product-table {
  display: block;
  --tableTemplateRows: repeat(6, 22px);
}
.product-table__title {
  font-family: var(--archivo);
  font-size: 1rem;
  font-weight: 600;
  color: #687c97;
  margin-bottom: 1rem;
}

.product-table__grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: var(--tableTemplateRows);
  grid-auto-flow: row;
}

.product-table__grid-box {
  display: flex;
  align-items: center;
}

.product-table__grid-box__label {
  flex: 1 1;
  font-family: var(--archivo);
  font-size: 14px;
  letter-spacing: 0.55px;
  color: #687c97;
}
.product-table__grid-box__value {
  flex: 1 1;
  font-family: var(--archivo);
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.33px;
  color: #687c97;
  text-transform: uppercase;
}

.Order_container__1FM7j {
  max-width: 100%;
}

.Order_main__3yVD0 {
  border: solid 1px #edf2f6;
  background-color: #f5f7f9;
  padding: 1rem;
  width: 500px;
  border-radius: 10px;
  position: relative;
}

.Order_title__2tfhQ {
  font-family: var(--archivo);
  font-size: 18px;
  font-weight: 600;
  color: #687c97;
}

.Order_boxes__2J8_R {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 1.25rem;
  row-gap: 1.25rem;
  grid-column-gap: 1rem;
  column-gap: 1rem;
  margin: 1.5rem 0;
}

.Order_box__1r3JT {
  border-left: 1.5px solid rgba(104, 124, 151, 0.3);
  padding-left: 12px;
}

.Order_boxLabel__16CWr {
  font-family: var(--archivo);
  font-size: 13px;
  letter-spacing: 0.58px;
  color: #687c97;
  margin-bottom: 8px;
}

.Order_boxValue__1R7W8 {
  font-family: var(--archivo);
  font-size: 17px;
  color: #687c97;
}

.Order_diamonds__95CIC {
  width: 100%;
  border-radius: 15px;
  -webkit-backdrop-filter: blur(2.7px);
          backdrop-filter: blur(2.7px);
  box-shadow: 0 5px 20px 0 rgba(74, 85, 100, 0.08);
  background-color: #ffffff;
  padding: 1.25rem;
}

.Order_diamondsTitle__3t0kI {
  font-family: var(--archivo);
  font-size: 18px;
  font-weight: 600;
  color: #687c97;
  margin-bottom: 1rem;
}

.Order_order__diamonds-list__SLJ2e {
  margin: 1.25rem 0 0 0;
}

.Order_divider__2vWDs {
  width: 100%;
  height: 2px;
  background: rgba(104, 124, 151, 0.21);
  margin: 1rem 0;
}

.Order_progressTitle__YtBkt {
  font-family: var(--archivo);
  font-size: 11.5px;
  letter-spacing: 0.58px;
  color: #687c97;
}

.Order_progressBox__FCkSp {
  border-radius: 15px;
  background-color: rgba(104, 124, 151, 0.06);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem 0;
  margin-top: 12px;
}

.Order_progressStep__32Hwa {
  padding: 4px 12px;
  background-color: #c2c4c5;
  border-radius: 30px;
  font-family: var(--archivo);
  font-size: 13.5px;
  font-weight: 500;
  letter-spacing: 0.68px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Order_progressStepSpacer__SHxjz {
  margin: 0 8px;
  color: #000;
  opacity: 0.2;
}

@media (max-width: 768px) {
  .Order_container__1FM7j {
    width: 100%;
    max-width: 100%;
  }

  .Order_main__3yVD0 {
    width: calc(100% - var(--sectionPadding) * 2);
    max-width: 31.25rem;
    margin: 0 auto;
    padding: var(--sectionPadding);
  }

  .Order_progressStep__32Hwa {
    font-size: 0.75rem;
  }

  .Order_title__2tfhQ {
    font-size: 1rem;
  }

  .Order_boxValue__1R7W8,
  .Order_ui-price__D1bez {
    font-size: 14px;
  }

  .Order_diamonds__95CIC {
    padding: var(--sectionPadding);
  }

  .Order_main__3yVD0 .tender-product__small-card {
    grid-template-columns: 70px auto;
  }
}

.TenderRegister_wrapper__2czJK {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.TenderRegister_container__3k1Bq {
  border: solid 1px #edf2f6;
  background-color: #f5f7f9;
  padding: 1rem;
  width: 500px;
  border-radius: 10px;
  position: relative;
  min-height: 200px;
  position: relative;
}

.TenderRegister_top__3-K7b {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
}

.TenderRegister_title__1Y1yg {
  font-family: var(--archivo);
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.42px;
  color: #222b45;
}

.TenderRegister_title__1Y1yg span {
  color: rgba(104, 124, 151, 0.67);
}

.TenderRegister_time__3jhDJ {
  font-family: var(--archivo);
  font-size: 13px;
  color: #687c97;
}

.TenderRegister_details__3o1RS {
  display: grid;
  grid-template-columns: repeat(5, -webkit-min-content);
  grid-template-columns: repeat(5, min-content);
  white-space: nowrap;
  grid-gap: 10px;
  gap: 10px;
  padding-bottom: 1rem;
  border-bottom: solid 1.7px rgba(104, 124, 151, 0.2);
}

.TenderRegister_summary__3S5ST {
  padding: 0.75rem 0;
  border-bottom: solid 1.7px rgba(104, 124, 151, 0.2);
  display: grid;
  grid-template-columns: -webkit-min-content -webkit-min-content;
  grid-template-columns: min-content min-content;
  grid-gap: 2rem;
  gap: 2rem;
}

.TenderRegister_summaryBox___Qx3v {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.TenderRegister_iconContainer__2I-ca {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #fff;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TenderRegister_icon__2NJJW {
  color: #687c97;
  width: 14px;
}

.TenderRegister_summaryBoxValue__2LRE8 {
  font-family: var(--archivo);
  font-size: 16px;
  font-weight: 600;
  color: #222b45;
  margin-right: 4px;
}

.TenderRegister_summaryBoxLabel__1H5V4 {
  font-family: var(--archivo);
  font-size: 13px;
  font-weight: 600;
  color: #687c97;
  margin-top: 2px;
}

.TenderRegister_description__TvhUd {
  font-family: var(--archivo);
  font-size: 13.5px;
  letter-spacing: 0.27px;
  color: #687c97;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.TenderRegister_actions__3Kxcz {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 80%;
  white-space: nowrap;
  grid-gap: 10px;
  gap: 10px;
}

.TenderRegister_actions__3Kxcz button {
  padding: 10px 28px;
}

.TenderRegister_register__xdsxq {
  border-radius: 6px;
  background-color: #32dea5;
  font-family: var(--archivo);
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.75px;
  color: #ffffff;
}

.TenderRegister_diamonds__2S7xZ {
  box-shadow: 0 5px 20px 0 rgba(74, 85, 100, 0.08);
  background-color: #ffffff;
  padding: 1rem;
  margin: 1rem 0 0 0;
  border-radius: 6px;
}

.TenderRegister_diamondsTitle__x0xNr {
  font-family: var(--archivo);
  font-size: 16px;
  font-weight: 600;
  color: #687c97;
  margin-bottom: 1rem;
}

.TenderRegister_diamondsList__1MSK1 {
  display: grid;
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
}

.header-container-guest {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
  width: 100%;
  height: 83px;
  padding-top: 10px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  font-family: var(--poppins);

  transition: all 0.2s;
}
.header-container-guest img {
  width: 173px;
  height: 32px;
}
.header-container-guest.scrolled {
  background: #ffffff;
  height: 83px;
  padding-top: 0px;
  position: fixed;
  box-shadow: 0 12px 64px 0 rgba(0, 0, 0, 0.06);
}
.header-container-guest .navigation-container a {
  margin-right: 24px;
  text-decoration: none;
  color: #ffffff;
  opacity: 0.64;
  cursor: pointer;
}
.header-container-guest .navigation-container a:nth-last-child(1) {
  margin: 0;
}
.header-container-guest .navigation-container a.active {
  opacity: 1;
  font-weight: bold;
}
.header-container-guest .navigation-container a.scrolled {
  color: #2a589f;
}
.header-container-guest .user-actions-container a {
  text-decoration: none;
  color: #ffffff;
}
.header-container-guest .user-actions-container a.scrolled {
  color: #2a589f;
}
.header-container-guest .user-actions-container .global-button {
  border-radius: 5px;
  box-shadow: 2px 2px 33px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
  color: #2a589f;
  border: none;
  padding: 12px 28px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.27);
}
.header-container-guest .user-actions-container .global-button.scrolled {
  color: #ffffff;
  background-image: linear-gradient(110deg, #003f69, #7499ff);
}
.header-container-guest .hamburger {
  display: none;
  background: none;
  border: none;
}
.mobile-container-guest {
  display: none;
}
.close-menu-screen {
  display: none;
}
/* Responsive */
@media (min-width: 1600px) {
  .header-container-guest {
    padding: 0 calc((100vw - 1600px) / 2);
  }
}
@media (max-width: 1200px) {
  .header-container-guest {
    justify-content: flex-start;
  }
  .header-container-guest .navigation-container {
    display: none;
  }
  .header-container-guest .user-actions-container {
    margin-left: auto;
    margin-bottom: 5px;
  }
  .header-container-guest .user-actions-container a {
    font-weight: bold;
    margin-right: 0 !important;
  }
  .header-container-guest .user-actions-container a:nth-last-child(1) {
    display: none;
  }
  .header-container-guest .hamburger {
    display: unset;
    margin-right: 24px;
  }
  .mobile-container-guest {
    display: unset;
    width: 70vw;
    max-width: 500px;
    min-width: 200px;
    background-color: #ffffff;
    height: calc(100vh - 83px);
    position: fixed;
    top: 83px;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 98;
    transform: translateX(0%);
    transition: transform ease 0.3s;
  }
  .mobile-container-guest.open {
    transform: translateX(-100%);
  }
  .mobile-container-guest .navigation-container-mobile {
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    font-size: 1.1rem;
    font-family: var(--roboto);
    font-weight: bold;
    padding-top: 5%;
    width: 100%;
  }
  .mobile-container-guest .navigation-container-mobile a,
  .mobile-container-guest .navigation-container-mobile button {
    text-align: left;
    width: 70%;
    color: rgba(33, 33, 33, 0.8);
    border: none;
    background: none;
    font-family: var(--poppins);
    font-weight: bold;
  }
  .mobile-container-guest .user-actions-container-mobile {
    margin: 20% 0;
    font-family: var(--poppins);
  }
  .mobile-container-guest .user-actions-container-mobile a:nth-child(1) {
    border-right: solid 1px rgba(42, 88, 159, 0.2);
    margin: 0;
    padding-right: 24px;
  }
  .close-menu-screen {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 97;
    background-color: rgba(33, 33, 33, 0.2);
    top: 0;
  }
  .close-menu-screen.open {
    display: unset;
  }
}

.accordion-drawer {
  width: 100%;
  min-height: 8%;
  border-bottom: solid 1px #f8f8f8;
  padding: 0 32px;
  font-family: var(--poppins);
}
.accordion-drawer.open {
  box-shadow: inset -1px 3px 35px -8px rgba(0, 0, 0, 0.12);
}
.accordion-drawer .custom-accordion__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 75px;
  opacity: 0.8;
}
.accordion-drawer .custom-accordion__header img {
  width: 18px;
}
.accordion-drawer .custom-accordion__header button {
  padding: 0;
}
.accordion-drawer .custom-accordion__body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
}
.accordion-drawer .custom-accordion__body a,
.accordion-drawer .custom-accordion__body button {
  font-size: 1rem;
  padding: 1rem 0;
  opacity: 0.6;
  white-space: nowrap;
}
.accordion-drawer .custom-accordion__body a.active,
.accordion-drawer .custom-accordion__body button.active {
  opacity: 1;
}

.header-container-user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
  width: 100%;
  height: 83px;
  background-image: linear-gradient(93deg, #003f69, #7499ff);
  position: relative;
  top: 0;
  left: 0;
  z-index: 100;
  font-family: roboto-reg;
  box-shadow: 0 12px 64px 0 rgba(0, 0, 0, 0.24);
}
.header-container-user .left {
  display: flex;
  align-items: center;
  height: 100%;
}
.header-container-user .left .hamburger {
  display: none;
}
.header-container-user .left .logo {
  width: 170px;
  margin-right: 50px;
  object-fit: none;
  width: auto;
}
.header-container-user .left .logo#blue {
  display: none;
}
.header-container-user .left .navigation-container {
  height: 100%;
  display: flex;
  align-items: center;
}
.header-container-user .left .navigation-container a {
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin-right: 16px;
  color: rgba(255, 255, 255, 0.605);
  height: 100%;
}
.header-container-user .left .navigation-container a:nth-of-type(3) {
  color: white;
  background-color: rgba(255, 255, 255, 0.05);
}
.header-container-user .left .navigation-container .new-link {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  background-color: #f58927;
  padding: 2px 4px;
  border-radius: 3px;
  margin-left: 10px;
  position: relative;
}
.header-container-user .left .navigation-container .new-link::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-right: 4px solid #f58927;
  border-bottom: 5px solid transparent;
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.header-container-user .user-actions-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.header-container-user .user-actions-container .notifications-container {
  position: relative;
  display: flex;
}
.header-container-user
  .user-actions-container
  .notifications-container
  .counter {
  width: rem(16px);
  height: rem(16px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: #ffffff;
  color: #f5a000;
  font-size: rem(12px);
  border-radius: 5px;
  right: 22px;
  top: -6px;
  padding: 2px;
}
.header-container-user
  .user-actions-container
  .notifications-container
  .visible {
  display: flex;
}
.header-container-user
  .user-actions-container
  .notifications-container
  .hidden {
  display: none;
}
.header-container-user .user-actions-container svg {
  margin-right: 32px;
  cursor: pointer;
}
.header-container-user .user-actions-container svg.stroke {
  stroke: #ffffff !important;
}
.header-container-user .user-actions-container svg.fill {
  fill: #ffffff !important;
}
.header-container-user .user-actions-container .left .wishlist-container {
  position: relative;
  display: flex;
  align-items: center;
}
.header-container-user
  .user-actions-container
  .left
  .wishlist-container
  .ui-counter {
  width: rem(16px);
  height: rem(16px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: #ffffff;
  color: #f5a000;
  font-size: rem(12px);
  border-radius: 5px;
  right: 22px;
  top: -6px;
  padding: 2px;
}

.header-container-user
  .user-actions-container
  .left
  .wishlist-container
  .visible {
  display: flex;
}
.header-container-user
  .user-actions-container
  .left
  .wishlist-container
  .hidden {
  display: none;
}
.header-container-user .user-actions-container .right {
  border-left: solid 2px #f4f4f460;
  display: flex;
  align-items: center;
}
.header-container-user .user-actions-container .right p {
  margin: 0 15px;
  color: #ffffff;
  font-weight: 400;
  letter-spacing: 1.5px;
  text-transform: capitalize;
  font-family: var(--poppins);
}
.header-container-user .user-actions-container .right svg {
  width: 10px;
  height: 10px;
  cursor: pointer;
  margin-right: 0;
  stroke: #212121;
}
.header-container-user .user-actions-container .right #user {
  display: none;
}
.mobile-container-user {
  display: none;
}
.triangle {
  width: 50px;
  height: 50px;
  position: fixed;
  top: 75px;
  right: 5%;
  z-index: 100;
  border-radius: 2px;
  opacity: 0;
  transition: all ease 0.6s;
}
.triangle .arrow-up {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #ffffff;
}
.triangle .arrow-down {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  display: none;
  border-top: 20px solid #f00;
}
.triangle .arrow-right {
  width: 0;
  height: 0;
  border-top: 60px solid transparent;
  border-bottom: 60px solid transparent;
  display: none;
  border-left: 60px solid green;
}
.triangle .arrow-left {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  display: none;
  border-right: 10px solid blue;
}
.triangle.open {
  opacity: 1;
}
.user-actions-popup {
  min-width: 240px;
  margin: 0;
  position: fixed;
  top: 90px;
  right: 5%;
  z-index: 100;
  background-color: #ffffff;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  padding-right: 50px;
  font-family: roboto-reg;
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity ease 0.6s, height ease 0.6s;
}
.user-actions-popup.open {
  opacity: 1;
  height: 540px;
}
.user-actions-popup.open-buyer {
  opacity: 1;
  height: 380px;
}
.user-actions-popup.user-actions-popup-lng {
  height: 462px;
}
.user-actions-popup .switch-animator {
  padding: 16px;
}
.user-actions-popup h5 {
  color: rgba(33, 33, 33, 0.64);
  font-size: 16px;
  margin-top: 12px;
  margin-bottom: 8px;
}
.user-actions-popup button {
  background: none;
  border: none;
  margin: 6px 0;
  font-size: 14px;
  opacity: 0.3;
  color: #212121;
  font-family: var(--roboto);
}
.user-actions-popup button a {
  opacity: 1;
  color: #212121;
}
.user-actions-popup button:hover {
  opacity: 0.6;
}
.user-actions-popup .user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 2px #efefef;
  padding-bottom: 12px;
}
.user-actions-popup .user h4 {
  font-size: 16px;
  margin-right: 16px;
  text-transform: capitalize;
}
.user-actions-popup .user p {
  font-size: 12px;
  color: rgba(209, 209, 209, 0.8);
  text-transform: capitalize;
}
.user-actions-popup .account {
  border-bottom: solid 2px #efefef;
  width: 100%;
  padding-bottom: 12px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.user-actions-popup .manage,
.user-actions-popup .trade {
  border-bottom: solid 2px #efefef;
  width: 100%;
  padding-bottom: 12px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.user-actions-popup .support {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.user-actions-popup .support span {
  display: flex;
  align-items: center;
}
.user-actions-popup .support span svg {
  width: 15px;
  opacity: 0.5;
  margin-left: 24px;
}
.user-actions-popup .support span img {
  width: 20px;
  margin-left: 24px;
}
.user-actions-popup .actions span {
  display: flex;
  align-items: center;
}
.user-actions-popup .actions span svg {
  width: 15px;
  opacity: 0.5;
  margin-left: 24px;
}
.user-actions-popup .actions span img {
  width: 24px;
  margin-left: 24px;
}
.user-actions-popup-mobile {
  margin: 0;
  position: fixed;
  top: 83px;
  z-index: 99;
  width: 100%;
  height: calc(100vh - 83px);
  background-color: #ffffff;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.16);
  font-family: var(--roboto);
  font-weight: bold;
  font-size: rem(18px);
  transform: translateY(-120%);
  transition: transform ease 0.6s;
  overflow-y: scroll;
}
.user-actions-popup-mobile.open {
  transform: translateY(0);
}
.user-actions-popup-mobile .user-info {
  width: 100%;
  height: 120px;
  padding: 0 32px;
  border-bottom: solid 1px #f8f8f8;
  display: flex;
  align-items: center;
}
.user-actions-popup-mobile .user-info .left {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  border: solid 2px #ffffff;
  background-color: #f5f5f5;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-actions-popup-mobile .user-info .left svg {
  stroke: rgba(203, 203, 203, 0.8);
}
.user-actions-popup-mobile .user-info .right .username {
  font-size: rem(18px);
  color: rgba(33, 33, 33, 0.8);
  font-family: var(--roboto);
  font-weight: bold;
  text-transform: capitalize;
}
.user-actions-popup-mobile .user-info .right .company {
  color: rgba(209, 209, 209, 0.8);
  font-size: rem(14px);
  margin-top: 8px;
  text-transform: capitalize;
}
.user-actions-popup-mobile .accordion-drawer {
  padding: 0 32px;
  width: 100%;
}
.user-actions-popup-mobile .accordion-drawer button {
  background: none;
  border: none;
  font-family: var(--roboto);
  font-weight: bold;
  font-size: rem(20px);
  width: 100%;
  display: flex;
  align-items: center;
}
.user-actions-popup-mobile .accordion-drawer button svg {
  width: 16px;
  margin-left: 24px;
}
.user-actions-popup-mobile .accordion-drawer .custom-accordion__body button,
.user-actions-popup-mobile .accordion-drawer .custom-accordion__body a {
  background: none;
  border: none;
  font-family: var(--roboto);
  font-weight: bold;
  font-size: 14px;
  color: #212121;
}
.user-actions-popup-mobile
  .accordion-drawer
  .custom-accordion__body
  button
  img {
  width: 24px;
  margin-left: auto;
  opacity: 1;
}
.user-actions-popup-mobile .flags-container .selected-lng {
  display: none;
}
.user-actions-popup-mobile .flags-container .title {
  display: none;
}
.user-actions-popup-mobile .flags-container .dropdown-flags {
  padding-bottom: 16px;
  margin: 0 16px;
}
.user-actions-popup-mobile .flags-container .dropdown-flags .lng-container {
  width: 100%;
  justify-content: space-between;
}
.close-menu-screen {
  display: none;
}
/* Responsive */
@media (min-width: 1600px) {
  .header-container-user {
    padding: 0 calc((100vw - 1600px) / 2);
  }
  .user-actions-popup {
    right: calc((100vw - 1600px) / 2);
  }
  .triangle {
    right: calc(((100vw - 1600px) / 2));
  }
}
@media (max-width: 1380px) {
  .header-container-user .left .navigation-container {
    display: none;
  }
  .header-container-user .left .hamburger {
    display: unset;
    background: none;
    border: none;
    margin-right: 24px;
  }
  .header-container-user .left .hamburger svg {
    stroke: #ffffff;
  }
  .mobile-container-user {
    display: unset;
    width: 70vw;
    max-width: 500px;
    min-width: 200px;
    background-color: #ffffff;
    height: calc(100vh - 83px);
    position: fixed;
    top: 83px;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 98;
    transform: translateX(0%);
    transition: transform ease 0.3s;
  }
  .mobile-container-user.open {
    transform: translateX(-100%);
  }
  .mobile-container-user .navigation-container-mobile {
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    font-size: 1.1rem;
    font-family: var(--roboto);
    font-weight: bold;
    padding-top: 5%;
    width: 100%;
  }
  .mobile-container-user .navigation-container-mobile a,
  .mobile-container-user .navigation-container-mobile button {
    text-align: left;
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    color: rgba(33, 33, 33, 0.8);
    border: none;
    background: none;
    font-family: var(--poppins);
    font-weight: bold;
    font-size: 1.1rem;
  }
  .mobile-container-user .navigation-container-mobile a.active,
  .mobile-container-user .navigation-container-mobile button.active {
    color: #0a0a0a;
  }
  .mobile-container-user .navigation-container-mobile .new-link {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    background-color: #f58927;
    padding: 2px 4px;
    border-radius: 3px;
    margin-left: 10px;
    position: relative;
  }
  .mobile-container-user .navigation-container-mobile .new-link::after {
    content: "";
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-right: 4px solid #f58927;
    border-bottom: 5px solid transparent;
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
  .mobile-container-user .navigation-container-mobile.trade .top {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75px;
    position: relative;
  }
  .mobile-container-user .navigation-container-mobile.trade .top button {
    width: 30%;
    position: absolute;
    left: 10%;
    font-size: 18px;
  }
  .mobile-container-user .navigation-container-mobile.trade .top .header {
    display: flex;
    align-items: center;
    padding: 0;
  }
  .mobile-container-user .user-actions-container-mobile {
    margin: 20% 0;
    font-family: roboto-reg;
  }
  .mobile-container-user .user-actions-container-mobile a:nth-child(1) {
    border-right: solid 1px rgba(42, 88, 159, 0.2);
    margin: 0;
    padding-right: 24px;
  }
  .close-menu-screen {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 97;
    background-color: rgba(33, 33, 33, 0.2);
  }
  .close-menu-screen.open {
    display: unset;
  }
}
@media (max-width: 768px) {
  .header-container-user .user-actions-container .left .wishlist-container {
    display: none;
  }

  .header-container-user {
    background-color: #ffffff;
    background-image: none;
  }
  .header-container-user .left .hamburger svg {
    stroke: #211f2c;
  }
  .header-container-user .left .logo {
    margin-right: 0;
  }
  .header-container-user .left .logo#white {
    display: none;
  }
  .header-container-user .left .logo#blue {
    display: unset;
  }
  .header-container-user .user-actions-container .left svg {
    display: none;
  }
  .header-container-user .user-actions-container .left svg.stroke {
    stroke: #211f2c !important;
  }
  .header-container-user .user-actions-container .left svg.fill {
    fill: #211f2c !important;
  }
  .header-container-user
    .user-actions-container
    .left
    div:nth-last-child(1)
    svg {
    display: unset;
    margin-right: 16px;
  }
  .header-container-user
    .user-actions-container
    .left
    .notifications-container {
    position: relative;
  }
  .header-container-user
    .user-actions-container
    .left
    .notifications-container
    .counter {
    background-image: linear-gradient(103deg, #003f69, #7499ff);
  }
  .header-container-user
    .user-actions-container
    .left
    .notifications-container
    span:not(.notification__body span) {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #32dea5;
    position: absolute;
    right: 12px;
    top: -4px;
    border: solid 1px #ffffff;
  }
  .header-container-user .user-actions-container .right {
    border-left: solid 2px #e2e2e2;
  }
  .header-container-user .user-actions-container .right svg {
    display: none;
  }
  .header-container-user .user-actions-container .right svg.stroke {
    stroke: #211f2c !important;
  }
  .header-container-user .user-actions-container .right svg.fill {
    fill: #211f2c !important;
  }
  .header-container-user .user-actions-container .right p {
    display: none;
  }
  .header-container-user .user-actions-container .right #user {
    display: unset;
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin: 0;
    margin-left: 16px;
  }
}
@media (max-width: 400px) {
  .header-container-user .left .hamburger {
    margin-right: 8px;
  }
}

.sub-header-user {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 50px;
  background-color: #ffffff;
  z-index: 98;
  transition: transform ease 0.2s;
  font-family: roboto-reg;
  padding: 0 5%;
  display: flex;
  align-items: center;
  box-shadow: 0 12px 64px 0 rgba(0, 0, 0, 0.24);
}
.sub-header-user.open {
  transform: translateY(83px);
}
.sub-header-user a {
  color: rgba(33, 33, 33, 0.5);
  padding: 0 16px;
  height: 100%;
  display: flex;
  align-items: center;
}
.sub-header-user a.active {
  opacity: 1;
  color: #005790;
  border-bottom: solid 4px #005790;
  font-family: var(--poppins);
  font-weight: bold;
}
.sub-header-user a:hover {
  background-color: rgba(0, 0, 0, 0.02);
}
.sub-header-user a:nth-child(1) {
  margin-left: 236px;
  padding: 0;
}
.sub-header-user button {
  border: none;
  background: none;
  padding: 0 16px;
  color: rgba(33, 33, 33, 0.5);
  font-family: var(--poppins);
  font-size: 16px;
  height: 100%;
  display: flex;
  align-items: center;
}
.sub-header-user button:hover {
  background-color: rgba(0, 0, 0, 0.02);
}
.sub-header-user button:nth-child(1) {
  margin-left: 236px;
  padding: 0;
}
/* Responsive */
@media (min-width: 1600px) {
  .sub-header-user {
    padding: 0 calc((100vw - 1600px) / 2);
  }
}
@media (max-width: 1280px) {
  .sub-header-user {
    justify-content: space-evenly;
  }
  .sub-header-user a,
  .sub-header-user button {
    padding: 0;
  }
  .sub-header-user a:nth-child(1),
  .sub-header-user button:nth-child(1) {
    margin: 0;
  }
}

.animate__appear {
  transition: opacity 0.6s, transform 0.6s;
  opacity: 0;
  transform: translateY(40px);
}

.animate__appear.inview {
  opacity: 1;
  transform: none;
  /* -webkit-transition-delay: 0.3s; */
  transition-delay: 0.3s;
}

.archivo {
  font-family: var(--archivo);
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 400ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}

.shimmer {
}

.shimmer--active {
  --color: #f6f7f8;
  --secondaryColor: #edeef1;
}

#root .shimmer--active .shine {
  background: var(--color);
  background-image: linear-gradient(
    to right,
    var(--color) 0%,
    var(--secondaryColor) 20%,
    var(--color) 40%,
    var(--color) 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 400px;
  /* position: relative; */
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
  color: transparent;
  pointer-events: none;
  border-color: transparent;
}

.shine--fit {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.shine--round {
  border-radius: 50%;
}

.shimmer--active .shine *:-ms-input-placeholder, .shimmer--active .shine:-ms-input-placeholder {
  color: transparent !important;
  background-color: transparent !important;
  visibility: hidden;
  opacity: 0;
  cursor: progress;
  pointer-events: none;
}

.shimmer--active .shine *,
.shimmer--active .shine *::after,
.shimmer--active .shine *::before,
.shimmer--active .shine *::placeholder,
.shimmer--active .shine::placeholder {
  color: transparent !important;
  background-color: transparent !important;
  visibility: hidden;
  opacity: 0;
  cursor: progress;
  pointer-events: none;
}

/* .shimmer--finished .shine > * {
  opacity: 1;
  transition: opacity 300ms ease-in;
} */

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important; */
  scroll-behavior: smooth;
  background: transparent;
  font-family: "Archivo", sans-serif;
  font-family: var(--archivo);
}

:root {
  --archivo: "Archivo", sans-serif;
  --roboto: "Roboto", sans-serif;
  --poppins: "Poppins", sans-serif;
  --headerHeight: 83px;
  --drawerWidth: calc(270px);
  --orange: #fa922a;
  --red: #ff0000;
  --green: #32dea5;
  --sectionPadding: 2rem;
}

.archivo {
  font-family: "Archivo", sans-serif;
  font-family: var(--archivo);
}

.roboto {
  font-family: "Roboto", sans-serif;
  font-family: var(--roboto);
}

html,
body,
#root {
  height: 100%;
  overflow: hidden;
}

/* @media (max-height: 800px) and (min-width: 1400px) {
  html {
    font-size: 13.5px;
  }
} */

a {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}

button {
  cursor: pointer;
  border: none;
}

label {
  display: block;
}

.page {
  max-height: 100%;
  min-height: 100%;
  overflow-y: auto;
  padding: 2rem;
  background-color: #f9fbfd;
  overflow-x: hidden;
}

.relative {
  position: relative;
}

.nowrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Toastify > * {
  z-index: 999999999;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-grow {
  flex: 1 1;
}

*:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

#mobile {
  display: none;
}

@media (max-width: 1280px) {
  :root {
    --sectionPadding: 2rem;
  }
}

@media (max-width: 1024px) {
  :root {
    --sectionPadding: 1.75rem;
  }
}

@media (max-width: 768px) {
  :root {
    --sectionPadding: 1.5rem;
  }
}

@media (max-width: 640px) {
  :root {
    --sectionPadding: 1rem;
  }

  #intercom-container {
    display: none;
  }
}

.elipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.scrollbar::-webkit-scrollbar {
  width: 4px;
  background-color: rgba(128, 128, 128, 0.3);
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: black;
}

.scrollbar--horizontal::-webkit-scrollbar {
  height: 8px;
  background-color: rgba(128, 128, 128, 0.3);
}

.scrollbar--horizontal::-webkit-scrollbar-thumb {
  background-color: black;
}

@media (max-width: 768px) {
  #intercom-frame,
  .intercom-lightweight-app {
    display: none;
  }
}

.transfer__title {
  font-family: "Poppins", sans-serif;
  font-family: var(--poppins);
  color: #212121;
  margin-top: 8px;
  font-weight: 500;
}

.transfer__subtitle {
  font-family: "Poppins", sans-serif;
  font-family: var(--poppins);
  color: #99a2b0;
  font-weight: 300;
}

