.tender-product__small-card {
  display: grid;
  grid-template-columns: 86px auto 46px;
  grid-template-rows: 82px;
  column-gap: 1rem;
  cursor: pointer;
}

.tender-product__small-card__details {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-rows: auto auto;
  align-items: center;
  padding: 0.5rem 0;
  position: relative;
}

.tender-product__small-card__details-highest {
  display: flex;
  align-items: center;
}

.tender-product__small-card__details-highest > span {
  margin-right: 8px;
  font-family: var(--archivo);
  font-size: 12px;
  letter-spacing: 0.53px;
  color: #687c97;
}

.tender-product__small-card .wishlist::after {
  content: "";
  height: 50%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1.7px;
  background-color: #edf2f6;
  position: absolute;
}

.tender-product__small-card__btn {
  margin: auto;
  color: #fff;
}

.tender-product__small-card .wishlist {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  padding: 0 0 0 1rem;
}
