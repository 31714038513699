.product-image__container {
  position: relative;
  overflow: hidden;
  text-align: center;
  width: 100%;
  height: 100%;
  /* background-color: #f5f7f9; */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-image {
  max-width: 100%;
  max-height: 100%;
  opacity: 0 !important;
  transition: opacity 0.3s ease-in;
  object-fit: contain;
  z-index: -1;
  height: auto;
  width: auto;
  border-radius: 6px;
}

.product-image--visible {
  opacity: 1 !important;
  z-index: 1;
}

.product-image--360 {
  width: 100%;
  height: 100%;
}

.product-image__default {
  width: auto;
  height: auto;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0 !important;
  transition: opacity 0.3s ease-in;
  object-fit: cover;
  z-index: -1;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
  top: 50%;
  border-radius: 6px;
}

.product-image__default--visible {
  opacity: 1 !important;
  z-index: 1;
}
