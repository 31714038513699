.container {
  max-width: 100%;
}

.main {
  border: solid 1px #edf2f6;
  background-color: #f5f7f9;
  padding: 1rem;
  width: 500px;
  border-radius: 10px;
  position: relative;
}

.title {
  font-family: var(--archivo);
  font-size: 18px;
  font-weight: 600;
  color: #687c97;
}

.boxes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 1.25rem;
  column-gap: 1rem;
  margin: 1.5rem 0;
}

.box {
  border-left: 1.5px solid rgba(104, 124, 151, 0.3);
  padding-left: 12px;
}

.boxLabel {
  font-family: var(--archivo);
  font-size: 13px;
  letter-spacing: 0.58px;
  color: #687c97;
  margin-bottom: 8px;
}

.boxValue {
  font-family: var(--archivo);
  font-size: 17px;
  color: #687c97;
}

.diamonds {
  width: 100%;
  border-radius: 15px;
  backdrop-filter: blur(2.7px);
  box-shadow: 0 5px 20px 0 rgba(74, 85, 100, 0.08);
  background-color: #ffffff;
  padding: 1.25rem;
}

.diamondsTitle {
  font-family: var(--archivo);
  font-size: 18px;
  font-weight: 600;
  color: #687c97;
  margin-bottom: 1rem;
}

.order__diamonds-list {
  margin: 1.25rem 0 0 0;
}

.divider {
  width: 100%;
  height: 2px;
  background: rgba(104, 124, 151, 0.21);
  margin: 1rem 0;
}

.progressTitle {
  font-family: var(--archivo);
  font-size: 11.5px;
  letter-spacing: 0.58px;
  color: #687c97;
}

.progressBox {
  border-radius: 15px;
  background-color: rgba(104, 124, 151, 0.06);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem 0;
  margin-top: 12px;
}

.progressStep {
  padding: 4px 12px;
  background-color: #c2c4c5;
  border-radius: 30px;
  font-family: var(--archivo);
  font-size: 13.5px;
  font-weight: 500;
  letter-spacing: 0.68px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progressStepSpacer {
  margin: 0 8px;
  color: #000;
  opacity: 0.2;
}

@media (max-width: 768px) {
  .container {
    width: 100%;
    max-width: 100%;
  }

  .main {
    width: calc(100% - var(--sectionPadding) * 2);
    max-width: 31.25rem;
    margin: 0 auto;
    padding: var(--sectionPadding);
  }

  .progressStep {
    font-size: 0.75rem;
  }

  .title {
    font-size: 1rem;
  }

  .boxValue,
  .ui-price {
    font-size: 14px;
  }

  .diamonds {
    padding: var(--sectionPadding);
  }

  .main :global(.tender-product__small-card) {
    grid-template-columns: 70px auto;
  }
}
