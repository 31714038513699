.animate__appear {
  -webkit-transition: opacity 0.6s, transform 0.6s;
  transition: opacity 0.6s, transform 0.6s;
  opacity: 0;
  -webkit-transform: translateY(40px);
  transform: translateY(40px);
}

.animate__appear.inview {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
  /* -webkit-transition-delay: 0.3s; */
  transition-delay: 0.3s;
}
