.header-container-user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
  width: 100%;
  height: 83px;
  background-image: linear-gradient(93deg, #003f69, #7499ff);
  position: relative;
  top: 0;
  left: 0;
  z-index: 100;
  font-family: roboto-reg;
  box-shadow: 0 12px 64px 0 rgba(0, 0, 0, 0.24);
}
.header-container-user .left {
  display: flex;
  align-items: center;
  height: 100%;
}
.header-container-user .left .hamburger {
  display: none;
}
.header-container-user .left .logo {
  width: 170px;
  margin-right: 50px;
  object-fit: none;
  width: auto;
}
.header-container-user .left .logo#blue {
  display: none;
}
.header-container-user .left .navigation-container {
  height: 100%;
  display: flex;
  align-items: center;
}
.header-container-user .left .navigation-container a {
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin-right: 16px;
  color: rgba(255, 255, 255, 0.605);
  height: 100%;
}
.header-container-user .left .navigation-container a:nth-of-type(3) {
  color: white;
  background-color: rgba(255, 255, 255, 0.05);
}
.header-container-user .left .navigation-container .new-link {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  background-color: #f58927;
  padding: 2px 4px;
  border-radius: 3px;
  margin-left: 10px;
  position: relative;
}
.header-container-user .left .navigation-container .new-link::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-right: 4px solid #f58927;
  border-bottom: 5px solid transparent;
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.header-container-user .user-actions-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.header-container-user .user-actions-container .notifications-container {
  position: relative;
  display: flex;
}
.header-container-user
  .user-actions-container
  .notifications-container
  .counter {
  width: rem(16px);
  height: rem(16px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: #ffffff;
  color: #f5a000;
  font-size: rem(12px);
  border-radius: 5px;
  right: 22px;
  top: -6px;
  padding: 2px;
}
.header-container-user
  .user-actions-container
  .notifications-container
  .visible {
  display: flex;
}
.header-container-user
  .user-actions-container
  .notifications-container
  .hidden {
  display: none;
}
.header-container-user .user-actions-container svg {
  margin-right: 32px;
  cursor: pointer;
}
.header-container-user .user-actions-container svg.stroke {
  stroke: #ffffff !important;
}
.header-container-user .user-actions-container svg.fill {
  fill: #ffffff !important;
}
.header-container-user .user-actions-container .left .wishlist-container {
  position: relative;
  display: flex;
  align-items: center;
}
.header-container-user
  .user-actions-container
  .left
  .wishlist-container
  .ui-counter {
  width: rem(16px);
  height: rem(16px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: #ffffff;
  color: #f5a000;
  font-size: rem(12px);
  border-radius: 5px;
  right: 22px;
  top: -6px;
  padding: 2px;
}

.header-container-user
  .user-actions-container
  .left
  .wishlist-container
  .visible {
  display: flex;
}
.header-container-user
  .user-actions-container
  .left
  .wishlist-container
  .hidden {
  display: none;
}
.header-container-user .user-actions-container .right {
  border-left: solid 2px #f4f4f460;
  display: flex;
  align-items: center;
}
.header-container-user .user-actions-container .right p {
  margin: 0 15px;
  color: #ffffff;
  font-weight: 400;
  letter-spacing: 1.5px;
  text-transform: capitalize;
  font-family: var(--poppins);
}
.header-container-user .user-actions-container .right svg {
  width: 10px;
  height: 10px;
  cursor: pointer;
  margin-right: 0;
  stroke: #212121;
}
.header-container-user .user-actions-container .right #user {
  display: none;
}
.mobile-container-user {
  display: none;
}
.triangle {
  width: 50px;
  height: 50px;
  position: fixed;
  top: 75px;
  right: 5%;
  z-index: 100;
  border-radius: 2px;
  opacity: 0;
  transition: all ease 0.6s;
}
.triangle .arrow-up {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #ffffff;
}
.triangle .arrow-down {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  display: none;
  border-top: 20px solid #f00;
}
.triangle .arrow-right {
  width: 0;
  height: 0;
  border-top: 60px solid transparent;
  border-bottom: 60px solid transparent;
  display: none;
  border-left: 60px solid green;
}
.triangle .arrow-left {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  display: none;
  border-right: 10px solid blue;
}
.triangle.open {
  opacity: 1;
}
.user-actions-popup {
  min-width: 240px;
  margin: 0;
  position: fixed;
  top: 90px;
  right: 5%;
  z-index: 100;
  background-color: #ffffff;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  padding-right: 50px;
  font-family: roboto-reg;
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity ease 0.6s, height ease 0.6s;
}
.user-actions-popup.open {
  opacity: 1;
  height: 540px;
}
.user-actions-popup.open-buyer {
  opacity: 1;
  height: 380px;
}
.user-actions-popup.user-actions-popup-lng {
  height: 462px;
}
.user-actions-popup .switch-animator {
  padding: 16px;
}
.user-actions-popup h5 {
  color: rgba(33, 33, 33, 0.64);
  font-size: 16px;
  margin-top: 12px;
  margin-bottom: 8px;
}
.user-actions-popup button {
  background: none;
  border: none;
  margin: 6px 0;
  font-size: 14px;
  opacity: 0.3;
  color: #212121;
  font-family: var(--roboto);
}
.user-actions-popup button a {
  opacity: 1;
  color: #212121;
}
.user-actions-popup button:hover {
  opacity: 0.6;
}
.user-actions-popup .user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 2px #efefef;
  padding-bottom: 12px;
}
.user-actions-popup .user h4 {
  font-size: 16px;
  margin-right: 16px;
  text-transform: capitalize;
}
.user-actions-popup .user p {
  font-size: 12px;
  color: rgba(209, 209, 209, 0.8);
  text-transform: capitalize;
}
.user-actions-popup .account {
  border-bottom: solid 2px #efefef;
  width: 100%;
  padding-bottom: 12px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.user-actions-popup .manage,
.user-actions-popup .trade {
  border-bottom: solid 2px #efefef;
  width: 100%;
  padding-bottom: 12px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.user-actions-popup .support {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.user-actions-popup .support span {
  display: flex;
  align-items: center;
}
.user-actions-popup .support span svg {
  width: 15px;
  opacity: 0.5;
  margin-left: 24px;
}
.user-actions-popup .support span img {
  width: 20px;
  margin-left: 24px;
}
.user-actions-popup .actions span {
  display: flex;
  align-items: center;
}
.user-actions-popup .actions span svg {
  width: 15px;
  opacity: 0.5;
  margin-left: 24px;
}
.user-actions-popup .actions span img {
  width: 24px;
  margin-left: 24px;
}
.user-actions-popup-mobile {
  margin: 0;
  position: fixed;
  top: 83px;
  z-index: 99;
  width: 100%;
  height: calc(100vh - 83px);
  background-color: #ffffff;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.16);
  font-family: var(--roboto);
  font-weight: bold;
  font-size: rem(18px);
  transform: translateY(-120%);
  transition: transform ease 0.6s;
  overflow-y: scroll;
}
.user-actions-popup-mobile.open {
  transform: translateY(0);
}
.user-actions-popup-mobile .user-info {
  width: 100%;
  height: 120px;
  padding: 0 32px;
  border-bottom: solid 1px #f8f8f8;
  display: flex;
  align-items: center;
}
.user-actions-popup-mobile .user-info .left {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  border: solid 2px #ffffff;
  background-color: #f5f5f5;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-actions-popup-mobile .user-info .left svg {
  stroke: rgba(203, 203, 203, 0.8);
}
.user-actions-popup-mobile .user-info .right .username {
  font-size: rem(18px);
  color: rgba(33, 33, 33, 0.8);
  font-family: var(--roboto);
  font-weight: bold;
  text-transform: capitalize;
}
.user-actions-popup-mobile .user-info .right .company {
  color: rgba(209, 209, 209, 0.8);
  font-size: rem(14px);
  margin-top: 8px;
  text-transform: capitalize;
}
.user-actions-popup-mobile .accordion-drawer {
  padding: 0 32px;
  width: 100%;
}
.user-actions-popup-mobile .accordion-drawer button {
  background: none;
  border: none;
  font-family: var(--roboto);
  font-weight: bold;
  font-size: rem(20px);
  width: 100%;
  display: flex;
  align-items: center;
}
.user-actions-popup-mobile .accordion-drawer button svg {
  width: 16px;
  margin-left: 24px;
}
.user-actions-popup-mobile .accordion-drawer .custom-accordion__body button,
.user-actions-popup-mobile .accordion-drawer .custom-accordion__body a {
  background: none;
  border: none;
  font-family: var(--roboto);
  font-weight: bold;
  font-size: 14px;
  color: #212121;
}
.user-actions-popup-mobile
  .accordion-drawer
  .custom-accordion__body
  button
  img {
  width: 24px;
  margin-left: auto;
  opacity: 1;
}
.user-actions-popup-mobile .flags-container .selected-lng {
  display: none;
}
.user-actions-popup-mobile .flags-container .title {
  display: none;
}
.user-actions-popup-mobile .flags-container .dropdown-flags {
  padding-bottom: 16px;
  margin: 0 16px;
}
.user-actions-popup-mobile .flags-container .dropdown-flags .lng-container {
  width: 100%;
  justify-content: space-between;
}
.close-menu-screen {
  display: none;
}
/* Responsive */
@media (min-width: 1600px) {
  .header-container-user {
    padding: 0 calc((100vw - 1600px) / 2);
  }
  .user-actions-popup {
    right: calc((100vw - 1600px) / 2);
  }
  .triangle {
    right: calc(((100vw - 1600px) / 2));
  }
}
@media (max-width: 1380px) {
  .header-container-user .left .navigation-container {
    display: none;
  }
  .header-container-user .left .hamburger {
    display: unset;
    background: none;
    border: none;
    margin-right: 24px;
  }
  .header-container-user .left .hamburger svg {
    stroke: #ffffff;
  }
  .mobile-container-user {
    display: unset;
    width: 70vw;
    max-width: 500px;
    min-width: 200px;
    background-color: #ffffff;
    height: calc(100vh - 83px);
    position: fixed;
    top: 83px;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 98;
    transform: translateX(0%);
    transition: transform ease 0.3s;
  }
  .mobile-container-user.open {
    transform: translateX(-100%);
  }
  .mobile-container-user .navigation-container-mobile {
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    font-size: 1.1rem;
    font-family: var(--roboto);
    font-weight: bold;
    padding-top: 5%;
    width: 100%;
  }
  .mobile-container-user .navigation-container-mobile a,
  .mobile-container-user .navigation-container-mobile button {
    text-align: left;
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    color: rgba(33, 33, 33, 0.8);
    border: none;
    background: none;
    font-family: var(--poppins);
    font-weight: bold;
    font-size: 1.1rem;
  }
  .mobile-container-user .navigation-container-mobile a.active,
  .mobile-container-user .navigation-container-mobile button.active {
    color: #0a0a0a;
  }
  .mobile-container-user .navigation-container-mobile .new-link {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    background-color: #f58927;
    padding: 2px 4px;
    border-radius: 3px;
    margin-left: 10px;
    position: relative;
  }
  .mobile-container-user .navigation-container-mobile .new-link::after {
    content: "";
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-right: 4px solid #f58927;
    border-bottom: 5px solid transparent;
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
  .mobile-container-user .navigation-container-mobile.trade .top {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75px;
    position: relative;
  }
  .mobile-container-user .navigation-container-mobile.trade .top button {
    width: 30%;
    position: absolute;
    left: 10%;
    font-size: 18px;
  }
  .mobile-container-user .navigation-container-mobile.trade .top .header {
    display: flex;
    align-items: center;
    padding: 0;
  }
  .mobile-container-user .user-actions-container-mobile {
    margin: 20% 0;
    font-family: roboto-reg;
  }
  .mobile-container-user .user-actions-container-mobile a:nth-child(1) {
    border-right: solid 1px rgba(42, 88, 159, 0.2);
    margin: 0;
    padding-right: 24px;
  }
  .close-menu-screen {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 97;
    background-color: rgba(33, 33, 33, 0.2);
  }
  .close-menu-screen.open {
    display: unset;
  }
}
@media (max-width: 768px) {
  .header-container-user .user-actions-container .left .wishlist-container {
    display: none;
  }

  .header-container-user {
    background-color: #ffffff;
    background-image: none;
  }
  .header-container-user .left .hamburger svg {
    stroke: #211f2c;
  }
  .header-container-user .left .logo {
    margin-right: 0;
  }
  .header-container-user .left .logo#white {
    display: none;
  }
  .header-container-user .left .logo#blue {
    display: unset;
  }
  .header-container-user .user-actions-container .left svg {
    display: none;
  }
  .header-container-user .user-actions-container .left svg.stroke {
    stroke: #211f2c !important;
  }
  .header-container-user .user-actions-container .left svg.fill {
    fill: #211f2c !important;
  }
  .header-container-user
    .user-actions-container
    .left
    div:nth-last-child(1)
    svg {
    display: unset;
    margin-right: 16px;
  }
  .header-container-user
    .user-actions-container
    .left
    .notifications-container {
    position: relative;
  }
  .header-container-user
    .user-actions-container
    .left
    .notifications-container
    .counter {
    background-image: linear-gradient(103deg, #003f69, #7499ff);
  }
  .header-container-user
    .user-actions-container
    .left
    .notifications-container
    span:not(.notification__body span) {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #32dea5;
    position: absolute;
    right: 12px;
    top: -4px;
    border: solid 1px #ffffff;
  }
  .header-container-user .user-actions-container .right {
    border-left: solid 2px #e2e2e2;
  }
  .header-container-user .user-actions-container .right svg {
    display: none;
  }
  .header-container-user .user-actions-container .right svg.stroke {
    stroke: #211f2c !important;
  }
  .header-container-user .user-actions-container .right svg.fill {
    fill: #211f2c !important;
  }
  .header-container-user .user-actions-container .right p {
    display: none;
  }
  .header-container-user .user-actions-container .right #user {
    display: unset;
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin: 0;
    margin-left: 16px;
  }
}
@media (max-width: 400px) {
  .header-container-user .left .hamburger {
    margin-right: 8px;
  }
}
