.sub-header-user {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 50px;
  background-color: #ffffff;
  z-index: 98;
  transition: transform ease 0.2s;
  font-family: roboto-reg;
  padding: 0 5%;
  display: flex;
  align-items: center;
  box-shadow: 0 12px 64px 0 rgba(0, 0, 0, 0.24);
}
.sub-header-user.open {
  transform: translateY(83px);
}
.sub-header-user a {
  color: rgba(33, 33, 33, 0.5);
  padding: 0 16px;
  height: 100%;
  display: flex;
  align-items: center;
}
.sub-header-user a.active {
  opacity: 1;
  color: #005790;
  border-bottom: solid 4px #005790;
  font-family: var(--poppins);
  font-weight: bold;
}
.sub-header-user a:hover {
  background-color: rgba(0, 0, 0, 0.02);
}
.sub-header-user a:nth-child(1) {
  margin-left: 236px;
  padding: 0;
}
.sub-header-user button {
  border: none;
  background: none;
  padding: 0 16px;
  color: rgba(33, 33, 33, 0.5);
  font-family: var(--poppins);
  font-size: 16px;
  height: 100%;
  display: flex;
  align-items: center;
}
.sub-header-user button:hover {
  background-color: rgba(0, 0, 0, 0.02);
}
.sub-header-user button:nth-child(1) {
  margin-left: 236px;
  padding: 0;
}
/* Responsive */
@media (min-width: 1600px) {
  .sub-header-user {
    padding: 0 calc((100vw - 1600px) / 2);
  }
}
@media (max-width: 1280px) {
  .sub-header-user {
    justify-content: space-evenly;
  }
  .sub-header-user a,
  .sub-header-user button {
    padding: 0;
  }
  .sub-header-user a:nth-child(1),
  .sub-header-user button:nth-child(1) {
    margin: 0;
  }
}
