.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.container {
  border: solid 1px #edf2f6;
  background-color: #f5f7f9;
  padding: 1rem;
  width: 500px;
  border-radius: 10px;
  position: relative;
  min-height: 200px;
  position: relative;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
}

.title {
  font-family: var(--archivo);
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.42px;
  color: #222b45;
}

.title span {
  color: rgba(104, 124, 151, 0.67);
}

.time {
  font-family: var(--archivo);
  font-size: 13px;
  color: #687c97;
}

.details {
  display: grid;
  grid-template-columns: repeat(5, min-content);
  white-space: nowrap;
  gap: 10px;
  padding-bottom: 1rem;
  border-bottom: solid 1.7px rgba(104, 124, 151, 0.2);
}

.summary {
  padding: 0.75rem 0;
  border-bottom: solid 1.7px rgba(104, 124, 151, 0.2);
  display: grid;
  grid-template-columns: min-content min-content;
  gap: 2rem;
}

.summaryBox {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.iconContainer {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #fff;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  color: #687c97;
  width: 14px;
}

.summaryBoxValue {
  font-family: var(--archivo);
  font-size: 16px;
  font-weight: 600;
  color: #222b45;
  margin-right: 4px;
}

.summaryBoxLabel {
  font-family: var(--archivo);
  font-size: 13px;
  font-weight: 600;
  color: #687c97;
  margin-top: 2px;
}

.description {
  font-family: var(--archivo);
  font-size: 13.5px;
  letter-spacing: 0.27px;
  color: #687c97;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.actions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 80%;
  white-space: nowrap;
  gap: 10px;
}

.actions button {
  padding: 10px 28px;
}

.register {
  border-radius: 6px;
  background-color: #32dea5;
  font-family: var(--archivo);
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.75px;
  color: #ffffff;
}

.diamonds {
  box-shadow: 0 5px 20px 0 rgba(74, 85, 100, 0.08);
  background-color: #ffffff;
  padding: 1rem;
  margin: 1rem 0 0 0;
  border-radius: 6px;
}

.diamondsTitle {
  font-family: var(--archivo);
  font-size: 16px;
  font-weight: 600;
  color: #687c97;
  margin-bottom: 1rem;
}

.diamondsList {
  display: grid;
  row-gap: 0.5rem;
}
