.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  /* margin-bottom: 5rem; */
  padding-bottom: 5rem;
}

.top {
  display: flex;
  align-items: center;
  padding: var(--sectionPadding);
}

.summary {
  display: flex;
  align-items: center;
}

.summaryIcon {
  width: 20px;
  margin-right: 0.5rem;
  color: #687c97;
}

.summaryTitle {
  font-family: var(--archivo);
  font-size: 14px;
  font-weight: 600;
  color: #687c97;
}

.summaryNumber {
  font-size: 16px;
  margin: 0 2px;
}

.container :global(.ui-table__container) {
  --columns: minmax(50px, 70px) minmax(90px, 90px)
    repeat(10, minmax(120px, 0.6fr)) minmax(130px, 1fr);
  width: calc(100% - var(--sectionPadding) * 2);
  margin: 0 auto;
}

.model,
.carat,
.color,
.clarity,
.lab,
.fluor {
  text-transform: uppercase;
}

.filtersSummary {
  padding: 0 var(--sectionPadding);
  margin-top: -2rem;
  margin-bottom: 1rem;
  overflow: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.reservePrice {
  display: flex;
  align-items: center;
}

.reservePriceTotal {
  font-size: 13px;

  position: relative;
  top: 1px;
  left: -8px;
}

.line {
  flex: 1;
  height: 2px;
  background-color: #eaeef2;
  margin: 0 1.5rem;
}

.selected {
  padding: 11px 20.4px 11px 23.1px;
  border-radius: 30px;
  background-color: rgba(222, 230, 235, 0.31);
  font-family: var(--archivo);
  font-size: 13px;
  font-weight: 600;
  color: #687c97;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in;
  margin-left: 10px;
}

.selectedActive {
  color: #fff;
  background-color: var(--green);
}

.selectedIcon {
  margin-right: 6px;
  position: relative;
  top: -1px;
  width: 18px;
}

.wishlist {
  width: 100%;
  height: 100%;
  background: transparent;
}

.noData {
  margin-top: 4rem;
}

.export {
  display: flex;
  align-items: center;
  outline-style: none;
  text-decoration: none;
  border-right: solid 1.5px rgba(192, 203, 209, 0.28);
  border-left: solid 1.5px rgba(192, 203, 209, 0.28);
  padding: 0 12px;
  margin: 0 12px;
}

.exportText {
  font-family: var(--archivo);
  font-size: 13px;
  font-weight: 600;
  color: #687c97;
}

.exportIcon {
  width: 20px;
  color: #687c97;
  margin-right: 4px;
}

.table :global(.pagination) {
  margin: 0 2rem;
}

.container :global(.pagination) {
  margin: 0 2rem;
}

.container :global(.no-data) {
  width: calc(100% - 4rem);
  margin: 1rem auto;
}

.container :global(.ui-table__pagination) {
  padding: 0 calc(var(--sectionPadding) / 2);
}

@media (max-width: 640px) {
  .selected {
    width: 38px;
    height: 38px;
    min-width: 38px;
    min-height: 38px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: #ffffff;
    margin: 0;
  }

  .selectedActive {
    background-color: var(--green);
  }

  .selected span {
    display: none;
  }

  .selected svg {
    margin: 0;
  }

  .summaryTitle {
    white-space: nowrap;
  }

  .filtersSummary {
    margin-top: 0;
    flex-wrap: nowrap !important;
  }
}
