@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("./texts.css");
@import url("./animations.css");
@import url("./shimmer.css");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important; */
  scroll-behavior: smooth;
  background: transparent;
  font-family: var(--archivo);
}

:root {
  --archivo: "Archivo", sans-serif;
  --roboto: "Roboto", sans-serif;
  --poppins: "Poppins", sans-serif;
  --headerHeight: 83px;
  --drawerWidth: calc(270px);
  --orange: #fa922a;
  --red: #ff0000;
  --green: #32dea5;
  --sectionPadding: 2rem;
}

.archivo {
  font-family: var(--archivo);
}

.roboto {
  font-family: var(--roboto);
}

html,
body,
#root {
  height: 100%;
  overflow: hidden;
}

/* @media (max-height: 800px) and (min-width: 1400px) {
  html {
    font-size: 13.5px;
  }
} */

a {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}

button {
  cursor: pointer;
  border: none;
}

label {
  display: block;
}

.page {
  max-height: 100%;
  min-height: 100%;
  overflow-y: auto;
  padding: 2rem;
  background-color: #f9fbfd;
  overflow-x: hidden;
}

.relative {
  position: relative;
}

.nowrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Toastify > * {
  z-index: 999999999;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-grow {
  flex: 1;
}

*:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

#mobile {
  display: none;
}

@media (max-width: 1280px) {
  :root {
    --sectionPadding: 2rem;
  }
}

@media (max-width: 1024px) {
  :root {
    --sectionPadding: 1.75rem;
  }
}

@media (max-width: 768px) {
  :root {
    --sectionPadding: 1.5rem;
  }
}

@media (max-width: 640px) {
  :root {
    --sectionPadding: 1rem;
  }

  #intercom-container {
    display: none;
  }
}

.elipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.scrollbar::-webkit-scrollbar {
  width: 4px;
  background-color: rgba(128, 128, 128, 0.3);
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: black;
}

.scrollbar--horizontal::-webkit-scrollbar {
  height: 8px;
  background-color: rgba(128, 128, 128, 0.3);
}

.scrollbar--horizontal::-webkit-scrollbar-thumb {
  background-color: black;
}

@media (max-width: 768px) {
  #intercom-frame,
  .intercom-lightweight-app {
    display: none;
  }
}

.transfer__title {
  font-family: var(--poppins);
  color: #212121;
  margin-top: 8px;
  font-weight: 500;
}

.transfer__subtitle {
  font-family: var(--poppins);
  color: #99a2b0;
  font-weight: 300;
}
