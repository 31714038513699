.header-container-guest {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
  width: 100%;
  height: 83px;
  padding-top: 10px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  font-family: var(--poppins);

  transition: all 0.2s;
}
.header-container-guest img {
  width: 173px;
  height: 32px;
}
.header-container-guest.scrolled {
  background: #ffffff;
  height: 83px;
  padding-top: 0px;
  position: fixed;
  box-shadow: 0 12px 64px 0 rgba(0, 0, 0, 0.06);
}
.header-container-guest .navigation-container a {
  margin-right: 24px;
  text-decoration: none;
  color: #ffffff;
  opacity: 0.64;
  cursor: pointer;
}
.header-container-guest .navigation-container a:nth-last-child(1) {
  margin: 0;
}
.header-container-guest .navigation-container a.active {
  opacity: 1;
  font-weight: bold;
}
.header-container-guest .navigation-container a.scrolled {
  color: #2a589f;
}
.header-container-guest .user-actions-container a {
  text-decoration: none;
  color: #ffffff;
}
.header-container-guest .user-actions-container a.scrolled {
  color: #2a589f;
}
.header-container-guest .user-actions-container .global-button {
  border-radius: 5px;
  box-shadow: 2px 2px 33px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
  color: #2a589f;
  border: none;
  padding: 12px 28px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.27);
}
.header-container-guest .user-actions-container .global-button.scrolled {
  color: #ffffff;
  background-image: linear-gradient(110deg, #003f69, #7499ff);
}
.header-container-guest .hamburger {
  display: none;
  background: none;
  border: none;
}
.mobile-container-guest {
  display: none;
}
.close-menu-screen {
  display: none;
}
/* Responsive */
@media (min-width: 1600px) {
  .header-container-guest {
    padding: 0 calc((100vw - 1600px) / 2);
  }
}
@media (max-width: 1200px) {
  .header-container-guest {
    justify-content: flex-start;
  }
  .header-container-guest .navigation-container {
    display: none;
  }
  .header-container-guest .user-actions-container {
    margin-left: auto;
    margin-bottom: 5px;
  }
  .header-container-guest .user-actions-container a {
    font-weight: bold;
    margin-right: 0 !important;
  }
  .header-container-guest .user-actions-container a:nth-last-child(1) {
    display: none;
  }
  .header-container-guest .hamburger {
    display: unset;
    margin-right: 24px;
  }
  .mobile-container-guest {
    display: unset;
    width: 70vw;
    max-width: 500px;
    min-width: 200px;
    background-color: #ffffff;
    height: calc(100vh - 83px);
    position: fixed;
    top: 83px;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 98;
    transform: translateX(0%);
    transition: transform ease 0.3s;
  }
  .mobile-container-guest.open {
    transform: translateX(-100%);
  }
  .mobile-container-guest .navigation-container-mobile {
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    font-size: 1.1rem;
    font-family: var(--roboto);
    font-weight: bold;
    padding-top: 5%;
    width: 100%;
  }
  .mobile-container-guest .navigation-container-mobile a,
  .mobile-container-guest .navigation-container-mobile button {
    text-align: left;
    width: 70%;
    color: rgba(33, 33, 33, 0.8);
    border: none;
    background: none;
    font-family: var(--poppins);
    font-weight: bold;
  }
  .mobile-container-guest .user-actions-container-mobile {
    margin: 20% 0;
    font-family: var(--poppins);
  }
  .mobile-container-guest .user-actions-container-mobile a:nth-child(1) {
    border-right: solid 1px rgba(42, 88, 159, 0.2);
    margin: 0;
    padding-right: 24px;
  }
  .close-menu-screen {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 97;
    background-color: rgba(33, 33, 33, 0.2);
    top: 0;
  }
  .close-menu-screen.open {
    display: unset;
  }
}
