.container {
  width: 360px;
  height: var(--headerHeight);
  box-shadow: -3px 0 20px 0 rgba(187, 181, 198, 0.84);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 0 1.5rem;
  cursor: pointer;
  background-image: linear-gradient(118deg, #482cbb -26%, #231659 150%);
  color: #ffffff;
}

.left {
  display: flex;
  align-items: center;
}

.leftIcon {
  color: #fff;
  fill: #fff;
  filter: invert();
}

.leftText {
  font-family: var(--archivo);
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  margin-left: 8px;
}

.right {
  display: flex;
  align-items: center;
}

.total {
  font-family: var(--archivo);
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.arrowContainer {
  width: 32px;
  height: 32px;
  border-radius: 6px;
  background-color: #eaeef2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.56);
}

.arrow {
  color: #ffffff;
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
}
