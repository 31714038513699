.accordion-drawer {
  width: 100%;
  min-height: 8%;
  border-bottom: solid 1px #f8f8f8;
  padding: 0 32px;
  font-family: var(--poppins);
}
.accordion-drawer.open {
  -webkit-box-shadow: inset -1px 3px 26px -8px rgba(0, 0, 0, 0.89);
  box-shadow: inset -1px 3px 35px -8px rgba(0, 0, 0, 0.12);
}
.accordion-drawer .custom-accordion__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 75px;
  opacity: 0.8;
}
.accordion-drawer .custom-accordion__header img {
  width: 18px;
}
.accordion-drawer .custom-accordion__header button {
  padding: 0;
}
.accordion-drawer .custom-accordion__body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
}
.accordion-drawer .custom-accordion__body a,
.accordion-drawer .custom-accordion__body button {
  font-size: 1rem;
  padding: 1rem 0;
  opacity: 0.6;
  white-space: nowrap;
}
.accordion-drawer .custom-accordion__body a.active,
.accordion-drawer .custom-accordion__body button.active {
  opacity: 1;
}
